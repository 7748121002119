import React, {Component, Fragment} from 'react'
import jwtDecode from "jwt-decode";
import {haulcheckURl, assuredURL, assuredAPIURL, earthidealURL, earthidealAPIURL, url} from '../../constants';
import ajax from 'superagent';
import SandboxCore from './SandboxCore';
import Key from './Key';
import _ from 'lodash';
import {
    ApiService,
    HaulCheckService,
    StatesService,
    //PublishSubscribe,
    //PMGroupsService,
} from '../../services'
/*import {
    updateObjectBasedOnKey,
    addChildToArray,
    removeChildFromArray
} from '../../utils'*/

export default class SandboxMap extends Component {

    constructor(props){
        super(props);
        const userDecoded = jwtDecode(localStorage.getItem('token'));
        let username = userDecoded['cognito:username'];

        this.state = {
            username,
            searchTerm: "",
            searchResult: null,
            noResultsFound: false,
            loading: false, 
            showMap: false,
            sandboxHeight: 'calc(100vh - '+(this.props.isBrowser?(86.5+110):(86.5+110+35))+'px)',
            sitesList: undefined,
            groupSitesList: undefined,
            client: null,
            defaultCoords: [],
            promotionalSites: [],
        }
    }

    componentDidMount = () => {
        this.setSearchBarHeight();

        if (this.props.displaySites){
            this.loadMapData();
            //this.setSubscription();
        } else {
            this.setState({showMap: true})
        }

    }

    /*componentWillUnmount = () => {
        this.closeSubscription();
    }

    setSubscription = async () => {
        let {client} = this.state;
        const userDecoded = jwtDecode(localStorage.getItem('token'));
        let username = userDecoded['cognito:username'];
        let email = userDecoded['email']
        let idpmgroup = username;

        let group = await PMGroupsService.getPmGroupByEmail(email);

        if (group && group.idpmgroup){
            idpmgroup = group.idpmgroup;
        }

        if (this.props.displaySites && client === null){
            client = await PublishSubscribe.quickSubscribe(
                "SandboxMap/SandboxWrapper",
                "SI/sandbox/"+idpmgroup,
                this.handleMessage
            );

            this.setState({client});
        }
    }

    closeSubscription = () => {
        let {client} = this.state;

        if (client !== null){
            client = PublishSubscribe.closeClient(client)
            this.setState({client});
        }
    }

    handleMessage = async (message) => {
        console.log("SandboxWrapper handleMessage message:", message);
        let {sitesList, groupSitesList} = this.state;
        console.log("SandboxWrapper handleMessage sites:", sitesList, groupSitesList);

        if (message.type == "updated-site"){
            sitesList = updateObjectBasedOnKey(sitesList, message.data);
            groupSitesList = updateObjectBasedOnKey(groupSitesList, message.data);
        } else if (message.type == "updated-groupmembers"){
            this.loadSites();
            this.setSubscription();
            return;
        } else if (message.type == "new-site"){
            groupSitesList = addChildToArray(groupSitesList, message.data, "idsite", "name");

        }

        this.setState({sitesList, groupSitesList});
    }*/

    async componentWillReceiveProps(newProps) {
        if (!_.isEqual(this.props.states, newProps.states)){
            this.loadMapData()
        }

    }

    loadMapData = async () => {
        let sitesList = [];
        let groupSitesList = [];
        const userDecoded = jwtDecode(localStorage.getItem('token'));

        this.setState({showMap: false})
        let defaultCoords = [];
        let promotionalSites = [];

        if (this.props.displaySites == true){

            if (this.props.sitesList === undefined){
                let username = userDecoded['cognito:username'];
                let res = await HaulCheckService.getHCSidebarMySites(username)
                sitesList = res.fullList;

                if (!_.isEmpty(sitesList) && !_.isEmpty(res.coordBounds)) {
                    defaultCoords = _.cloneDeep(res.coordBounds)
                }
            }

            console.log("defaultCoords after sitesList:", defaultCoords)

            if (this.props.groupSitesList === undefined){
                let email = userDecoded['email'];
                let res = await HaulCheckService.getHCSidebarGroupSites(email)
                groupSitesList = res.fullList

                if (!_.isEmpty(groupSitesList) && !_.isEmpty(res.coordBounds)) {
                    defaultCoords = _.cloneDeep(res.coordBounds)
                }
            }

            console.log("defaultCoords after groupSitesList:", defaultCoords)

        }

        
        let states = localStorage.getItem("defaultStates");
        let promotionalSitesParam = undefined;

        if (_.isEmpty(groupSitesList) && _.isEmpty(sitesList)){
            
            if (!_.isEmpty(this.props.states)) {
                promotionalSitesParam = this.props.states;
            } else if (!_.isEmpty(states)){
                promotionalSitesParam = states
            }

        }

        let promoData = await HaulCheckService.getHCPromotionalSites(promotionalSitesParam);
        console.log("promotionalSitesParam", promotionalSitesParam, "initial promoData", promoData)

        if (_.isEmpty(defaultCoords)){
            defaultCoords = promoData.stateCoords;
        }

        console.log("defaultCoords after promoData:", defaultCoords)

        promotionalSites = promoData.promotionalSites;
   

        let mySitesIndices = sitesList.map(({idsite}) => {return idsite});
        let promotionalSitesIndices = promotionalSites.map(({idsite}) => {return idsite});

        _.remove(groupSitesList, (site) => {
            return (
                mySitesIndices.indexOf(site.idsite) !== -1 || 
                promotionalSitesIndices.indexOf(site.idsite) !== -1
            )
        })

        _.remove(sitesList, (site) => {
            return (
                promotionalSitesIndices.indexOf(site.idsite) !== -1
            )
        })



        this.setState({sitesList, groupSitesList, defaultCoords, promotionalSites, showMap: true})
    }


    setSearchBarHeight = () => {
        let sandboxOffset = document.getElementById('key').offsetHeight;

        if (this.props.showSearchBar == true){
            let searchBar = document.getElementById('searchBar');
            sandboxOffset += searchBar.offsetHeight;
            console.log("sandbox offset", sandboxOffset);
        }

        let browserHeaderFooterOffset = 110;
        let mobileHeaderFooterOffset = 35;

        let finalOffset = browserHeaderFooterOffset + sandboxOffset;
        if (!this.props.isBrowser) finalOffset += mobileHeaderFooterOffset;

        let sandboxHeight = 'calc(100vh - '+finalOffset+'px)';
        this.setState({sandboxHeight})
    }

    searchForLocation = () => {
        let {searchTerm, defaultCenter} = this.state;
        this.setState({loading: true});

        ajax.get(`https://discover.search.hereapi.com/v1/discover?q=${searchTerm}&at=${defaultCenter.lat},${defaultCenter.lng}&apiKey=${process.env.REACT_APP_HERE_API_KEY}`)
        .end((error, response) => {
            if (!error && response) {
                console.log("FEA-801", "Search results:", response);
                if (response.body.items.length > 0){
                    console.log("FEA-801", "First result coordinates:", response.body.items[0].position);
                    this.setState({noResultsFound: false, searchResult: response.body.items[0].position, loading: false}, this.setSearchBarHeight);
                } else {
                    console.log("FEA-801", "No results found")
                    this.setState({noResultsFound: true, loading: false, searchResult: null}, this.setSearchBarHeight);
                }
            } else {
                console.log("FEA-901", 'There was an error', error);
                this.setState({noResultsFound: true, loading: false, searchResult: null}, this.setSearchBarHeight);
            }
        });
    }

    render = () => {

        return(
            <Fragment>

                {this.props.showSearchBar ? 
                    <div className="p-lg-5 p-3 bg-gray" id="searchBar">

                        <div class="form-group input-group mb-0">
                            <input class="form-control form-control-sm" type="text"
                                placeholder="Search address, intersection, or business"
                                value={this.state.searchTerm}
                                onChange={(e) => this.setState({searchTerm: e.target.value})}
                                onKeyUp={(e) => {
                                    if (e.keyCode == 13) {
                                        this.searchForLocation()
                                    }
                                }}
                            />
                                
                            <div class="input-group-append">
                                <button 
                                    class="cursor px-2"
                                    style={{backgroundColor: "white", border: "none", color: "rgb(128, 128, 128)"}}
                                    type="button" 
                                    onClick={() => {this.setState({searchTerm: ""})}} 
                                    disabled={this.state.loading}>
                                    <i className="ti ti-close"></i>
                                </button>
                                <button class="btn btn-primary" type="button" onClick={this.searchForLocation} disabled={this.state.loading}>
                                    {this.state.loading ? <img src="/img/app/photoswipe/preloader.gif" /> : <i className="ti ti-search"></i>}
                                </button>
                            </div>
                        </div>
                        {this.state.noResultsFound ? <span className="small"><em>No results found</em></span> : null}

                    </div>
                : null}

                <Key />

                <div style={{
                    height: this.state.sandboxHeight,
                    maxHeight: this.state.sandboxHeight,
                    minHeight: this.state.sandboxHeight,
                }}>
                    {this.state.showMap ? 

                        <SandboxCore
                            backendURL={earthidealAPIURL}
                            app={"HaulCheck"}
                            username={this.state.username}
                            locked = {this.props.locked}
                            setLocked={this.props.setMapLocked}
                            refreshSites = {async (newSite) => {
                                await ApiService.bulkSetSiteVisibility({sitesList: [{
                                  idsite: newSite.idHaulcheckSite,
                                  name: newSite.name,
                                  visible: 1
                                }]});
                            }}
                            mySitesList = {
                                this.props.sitesList !== undefined ? this.props.sitesList :
                                this.state.sitesList !== undefined ? this.state.sitesList : 
                                []
                            }
                            adSharedSite = {this.props.sharedSite}
                            hcGroupSites = {
                                this.props.groupSitesList !== undefined ? this.props.groupSitesList :
                                this.state.groupSitesList !== undefined ? this.state.groupSitesList : 
                                []
                            }
                            sharedRoutes={this.props.sharedRoutes}
                            originSite={this.props.originSite}
                            idsite_resource={this.props.idsite_resource}
                            toggleRouteVisible={this.props.toggleRouteVisible || null}
                            searchResult={this.state.searchResult}
                            activeSite={this.props.activeSite}
                            promotionalSites={this.state.promotionalSites}
                            defaultCoords = {
                                !_.isEmpty(this.state.defaultCoords) ? 
                                    this.state.defaultCoords 
                                : !_.isEmpty(this.props.routesMapBounds) ?
                                    this.props.routesMapBounds
                                : []
                            }
                        />
                    : null}
                </div>

            </Fragment>
        )
        //return null;
    }
}
