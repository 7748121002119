import React, {Fragment}  from 'react';
import Match from './Match'

export default function Material({material, openMessageThread, idsite, materialLoading}){

	let {matches, resource, type, idsite_resource} = material;

	return(
        <div class="mt-4">
          	<p class="strong mb-0">{resource} {type.split('/')[0]}</p>
        	<ul class="list-unstyled mx-4">
				{matches && matches.length > 0 ? matches.map((match) => {
					return <Match match={match} key={match.idmatchsiteresource + "-match"} openMessageThread={openMessageThread} idsite={idsite} idsite_resource={idsite_resource} />
				}) : 
					(materialLoading ? "Loading Matches": "You did not contact any of your external matches for this material")}
   			</ul>
		</div>
	)

}