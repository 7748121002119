import { url, earthidealAPIURL } from '../constants'
import {ApiService} from "../services"
import JwtDecode from 'jwt-decode'
import _ from "lodash"

export const HaulCheckService = {
  addNewSite,
  setup,
  updateSiteMaxDistance,
  getAllResources,
  getHCSidebarMySites,
  getHCSidebarGroupSites,
  getHCMaterialsForSidebarSite,
  getHCSidebarByMaterials,
  getHCPromotionalSites,
  setArchivedBySite,
  recoverArchivedSite,
  setArchived,
  unsetArchived,
  setAutorenewal,
  unsetAutorenewal,
  toggleStatus,
  setConstructionProject,
  unsetConstructionProject,
  addMonthToPostPeriod,
  updateQuantity,
  updateMatchCriteria,
  getBids,
  getPosts,
  getArchived,
  getResourcesBySite,
  getMaxDistanceBySite,
  addNewResources,
  getSharedSites,
  getMaterialInfo,
  getMatches,
  getArchiveSites,
  getArchiveMaterials,
  deleteMaterial,
  deleteAllMaterials,
  deleteSite,
  getExternalMatchTally
}

async function addNewSite (newSite) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      newSite
    })
  }

  const resp = await fetch(`${url.backend}/sites/add`, requestOptions)
    .then(handleResponse)

  return resp
}

async function setup (setupData) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      setupData
    })
  }

  const resp = await fetch(`${url.backend}/sites/setup`, requestOptions)
    .then(handleResponse)

  await this.getMatches(setupData.idsite);

  return resp
}

async function updateSiteMaxDistance (distanceData /*idsite, distance*/) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      distanceData
    })
  }

  const resp = await fetch(`${url.backend}/sites/updateSiteMaxDistance`, requestOptions)
    .then(handleResponse)

  await this.getMatches(distanceData.idsite);

  return resp
}

async function addNewResources (newResources) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      newResources
    })
  }

  const resp = await fetch(`${url.backend}/sites/addresources`, requestOptions)
    .then(handleResponse)

  await this.getMatches(newResources.idsite);

  return resp
}

async function getAllResources(username) {
  const resp = await fetch(`${url.backend}/sites/getallresources/${username}`).then(handleResponse)
  return resp
}

async function getHCSidebarMySites(username) {
  const resp = await fetch(`${url.backend}/sites/getHCSidebarMySites/${username}`).then(handleResponse)
  return resp.resourcesList
}

async function getHCSidebarGroupSites(email) {
  const resp = await fetch(`${url.backend}/sites/getHCSidebarGroupSites/${email}`).then(handleResponse)
  return resp.groupSitesList
}

async function getHCMaterialsForSidebarSite(idsite, includeArchive = false){
  const resp = await fetch(`${url.backend}/sites/getHCMaterialsForSidebarSite/${idsite}/${includeArchive}`).then(handleResponse)
  return resp.materials
}

async function getHCSidebarByMaterials(username, email) {
  const resp = await fetch(`${url.backend}/sites/getHCSidebarByMaterials/${email}/${username}`).then(handleResponse)
  return resp.resourcesList
}

async function getHCPromotionalSites(states) {

  console.log("Calling getHCPromotionalSites with states", states);

  let username = ApiService.getJWTUsername();
  let email = ApiService.getJWTEmail();

  console.log("getHCPromotionalSites fetched username and email:", username, email);

  if (!states || _.isEmpty(states)){
    states = "NA"
  } 

  console.log("getHCPromotionalSites final states", states)

  const resp = await fetch(`${url.backend}/sites/getHCPromotionalSites/${email}/${username}/${states}`).then(handleResponse)
  return resp;
}

async function getBids(username) {
  const resp = await fetch(`${url.backend}/sites/byuser/${username}/1`).then(handleResponse)
  return resp.sites
}

async function getPosts(username) {
  const resp = await fetch(`${url.backend}/sites/byuser/${username}/2`).then(handleResponse)
  return resp.sites
}

async function getArchived(username) {
  const resp = await fetch(`${url.backend}/sites/archived/${username}`).then(handleResponse)
  return resp.archivedList
}

async function getResourcesBySite(idsite) {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null

  const resp = await fetch(`${url.backend}/sites/getresourcesbysite/${idsite}`).then(handleResponse)
  return resp.resourcesList
}

async function getMaxDistanceBySite(idsite) {
  const resp = await fetch(`${url.backend}/sites/getMaxDistanceBySite/${idsite}`).then(handleResponse)
  return resp.maxDistance
}

async function setArchivedBySite(idsite) {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const email = userDecoded ? userDecoded['email'] : null;
  const name = userDecoded ? userDecoded['name'] : null;

  let req = {
    email, username, idsite, name
  }

    //console.log(req);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  }

  const resp = await fetch(`${url.backend}/sites/setarchivedbysite`, requestOptions)
    .then(handleResponse)

  await this.getMatches(idsite);

  return resp
}

async function recoverArchivedSite(idsite) {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const email = userDecoded ? userDecoded['email'] : null

  const resp = await fetch(`${url.backend}/sites/recoverArchivedSite/${idsite}/${username}/${email}`).then(handleResponse)

  await this.getMatches(idsite);

  return resp
}

async function setArchived(idsite_resource) {

  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const email = userDecoded ? userDecoded['email'] : null;
  const name = userDecoded ? userDecoded['name'] : null;

  let req = {
    email, username, idsite_resource, name
  }

    //console.log(req);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  }

  const resp = await fetch(`${url.backend}/sites/setarchived`, requestOptions)
    .then(handleResponse)

}

async function unsetArchived(idsite_resource) {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const email = userDecoded ? userDecoded['email'] : null;
  const name = userDecoded ? userDecoded['name'] : null;

  let req = {
    email, username, idsite_resource, name
  }

    //console.log(req);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  }

  const resp = await fetch(`${url.backend}/sites/unsetarchived`, requestOptions)
    .then(handleResponse)
}

async function setAutorenewal(id) {
  const resp = await fetch(`${url.backend}/sites/setautorenewal/${id}`).then(handleResponse)
  return resp
}

async function unsetAutorenewal(id) {
  const resp = await fetch(`${url.backend}/sites/unsetautorenewal/${id}`).then(handleResponse)
  return resp
}

async function toggleStatus(idsite_resource, isBlast = false) {

  console.log("Calling toggleStatus for idsite_resource " + idsite_resource, "with isBlast", isBlast);

  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  let numExternalMatches = 1;
  let blast = 1;

  if (!isBlast){
    const resp1 = await fetch(`${url.backend}/matches/getExternalMatchTally/${idsite_resource}`).then(handleResponse)
    numExternalMatches = resp1.tallyData.finalExternalMatchTally;
    blast = 0;
  }  

  console.log("toggleStatus numExternalMatches:", numExternalMatches);

  const resp = await fetch(`${url.backend}/sites/togglestatus/${idsite_resource}/${username}/${numExternalMatches}/${blast}`).then(handleResponse)
  return resp
}

async function setConstructionProject(id) {
  const resp = await fetch(`${url.backend}/sites/setconstructionproject/${id}`).then(handleResponse)
  await this.getMatches(id);
  return resp
}

async function unsetConstructionProject(id) {
  const resp = await fetch(`${url.backend}/sites/unsetconstructionproject/${id}`).then(handleResponse)
  await this.getMatches(id);
  return resp
}

async function addMonthToPostPeriod(id){
  const resp = await fetch(`${url.backend}/sites/addMonthToPostPeriod/${id}`).then(handleResponse)
  return resp
}

async function updateQuantity(req) {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const email = userDecoded ? userDecoded['email'] : null
  const name = userDecoded ? userDecoded['name'] : null
  req.username = username;
  req.name = name;
  req.email = email;

  //console.log(req);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  }

  const resp = await fetch(`${url.backend}/sites/updateQuantity`, requestOptions)
    .then(handleResponse)

  return resp
}

async function updateMatchCriteria (req) {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const email = userDecoded ? userDecoded['email'] : null
  const name = userDecoded ? userDecoded['name'] : null
  req.username = username;
  req.name = name;
  req.email = email;

  //console.log(req);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  }

  const resp = await fetch(`${url.backend}/sites/updateMatchCriteria`, requestOptions)
    .then(handleResponse)

  return resp
}

async function getSharedSites(idsite_resource, idsite){
  const resp = await fetch(`${url.backend}/sites/getSharedSites/${idsite_resource}/${idsite}`).then(handleResponse)
  return resp
}

async function getMaterialInfo(idsite_resource, origin = "[not entered]"){
  console.log("Calling getMaterialInfo from " + origin)
  if (idsite_resource != undefined){
    const resp = await fetch(`${url.backend}/sites/getMaterialInfo/${idsite_resource}`).then(handleResponse)
    return resp.data
  } else {
    return []
  }
}

async function getMatches(idsite){
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const email = userDecoded ? userDecoded['email'] : null
  const resp = await fetch(`${url.backend}/sites/getMatches/${email}/${idsite}`).then(handleResponse)
  return resp
}

async function getArchiveSites(){
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const email = userDecoded ? userDecoded['email'] : null
  const resp = await fetch(`${url.backend}/sites/getArchiveSites/${email}`).then(handleResponse)
  return resp.resourcesList;
}

async function getArchiveMaterials(idsite){
  const resp = await fetch(`${url.backend}/sites/getArchiveMaterials/${idsite}`).then(handleResponse)
  return resp.resources;
}

async function deleteMaterial (idsite_resource) {
  //console.log(req);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({idsite_resource})
  }

  const resp = await fetch(`${url.backend}/sites/deleteMaterial`, requestOptions)
    .then(handleResponse)

  return resp
}

async function deleteSite (idsite) {
  //console.log(req);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({idsite})
  }

  const resp = await fetch(`${url.backend}/sites/deleteSiteHC`, requestOptions)
    .then(handleResponse)

  return resp
}

async function deleteAllMaterials (idsite) {
  //console.log(req);
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({idsite})
  }

  const resp = await fetch(`${url.backend}/sites/deleteAllMaterials`, requestOptions)
    .then(handleResponse)

  return resp
}

async function getExternalMatchTally (idsite_resource) {
  const resp = await fetch(`${url.backend}/matches/getExternalMatchTally/${idsite_resource}`).then(handleResponse)
  return resp.tallyData;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

