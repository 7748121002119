import React, {Fragment, useState, useEffect}  from 'react';
import SiteDisplay from './SiteDisplay';
import _ from 'lodash';
import {
	PublishSubscribe,
	ApiService
	//HaulCheckService,
	//userService
} from '../../../services'
import {addChildToArray, removeChildFromArray, usePrevious} from '../../../utils'

export default function SiteList({name, passedSites, emptyState, listObj, switchSidePanel, hasGroup, activeSite, setActiveSite, selectedMaterial, setSelectedMaterial, openSiteTeamModal, getSiteInfo, openSettingsModal, deleteMaterial, editSiteName, deleteSite, activeTab, idpmgroup, newSite, moveSite, clearNewSite, addLength, removeLength, isMobile, isBrowser, hasExternalAccess}){

	let [sites, updateSites] = useState(passedSites ? _.cloneDeep(passedSites) : []);
	let [realSiteLength, setRealSiteLength] = useState(passedSites ? passedSites.length : 0);
	let previousRealSiteLength = usePrevious(realSiteLength, 0)
	let previousActiveTab = usePrevious(activeTab, "mySites")
	let [client, setClient] = useState(null);
	let iddiscoverymode = (listObj == "postsList" ? 2 : listObj == "bidsList" ? 1 : null)

	useEffect(() => {
		if (JSON.stringify(passedSites) != JSON.stringify(sites)){
			updateSites(passedSites ? _.cloneDeep(passedSites) : []);
			setRealSiteLength(passedSites ? passedSites.length : 0)
		}
	}, [passedSites])

	let setSubscription = async () => {
		let topic = "SI/sites/"+iddiscoverymode+"/";
		let username = await ApiService.getJWTUsername();

		if (activeTab == "groupSites"){
			topic += "group/"+idpmgroup
		} else if (activeTab == "mySites"){
			topic += "mine/"+username
		} else if (activeTab == "material"){
			topic += "material/" + (idpmgroup ? idpmgroup : username)
		}

		console.log("setSubscription topic:", )

		client = await PublishSubscribe.quickSubscribe(
			"SI-frontend/MySites/Sidebar/SiteList/"+listObj+"/"+iddiscoverymode,
			topic,
			handleMessage
		)

		setClient(client);
	}

	useEffect(() => {
		setSubscription()

		return(() => {
			if (client !== null){
				PublishSubscribe.closeClient(client, "MySites/Sidebar/SiteList/"+listObj+"/"+iddiscoverymode);
			}
		})
	}, [])

	useEffect(() => {
		console.log("---------- realSiteLength ----------")
		console.log("realSiteLength set to " + realSiteLength + " in listObj " + listObj);
		console.log("previous realSiteLength:", previousRealSiteLength);

		if (previousRealSiteLength > realSiteLength){
			removeLength();
		} else if (previousRealSiteLength < realSiteLength){
			addLength();
		}
		
	}, [realSiteLength])

	useEffect(() => {

		if ((activeTab == "material" && previousActiveTab !== "material") || (activeTab != "material" && activeTab == "material")){
			setActiveSite(null);
		}

	}, [activeTab])

	
	useEffect(() => {
		console.log("newSite in listObj " + listObj);
		if (newSite != null){
			console.log("newSite being passed to siteList "+listObj+" with iddiscoverymode "+newSite.iddiscoverymode+":", newSite);
			if (newSite.iddiscoverymode == iddiscoverymode){
				console.log("newSite being added to siteList with listObj " + listObj + " and newSite ", newSite);
				updateSites(previousState => addChildToArray(previousState, newSite, "idsite", "name"))
				setRealSiteLength(prev => prev+1);
				clearNewSite();
			}
		}
	}, [newSite])

	let handleMessage = (message) => {
		message = JSON.parse(message);

		if (message.type == "new-site"){
			updateSites((previousState) => {return addChildToArray(previousState, message.site, "idsite", "name")})
			setRealSiteLength(prev => prev+1)
		}
	}

	let renderSiteDisplay = (site, key) => {
		return <SiteDisplay
			isBrowser={isBrowser}
			isMobile={isMobile}
			passed_site={site}
			index={key}
			listObj={listObj}
			switchSidePanel={switchSidePanel}
			sortMode={activeTab}
			key={site.idsite || site.name}
			hasGroup={hasGroup}
			isActiveSite={activeTab != "material" ? activeSite == site.idsite : activeSite == key}
			setActiveSite={setActiveSite}
			setSelectedMaterial={setSelectedMaterial}
			selectedMaterial={selectedMaterial}
			openSiteTeamModal={openSiteTeamModal}
			getSiteInfo={getSiteInfo}
			openSettingsModal={openSettingsModal}
			canEditSite={ true }
			deleteMaterial={deleteMaterial}
			editSiteName={editSiteName}
			deleteSite={deleteSite}
			activeTab={activeTab}
			idpmgroup={idpmgroup}
			hasExternalAccess={hasExternalAccess}
			removeSelf={(idsite) => {
				if (activeTab != "material"){
					updateSites(previousState => removeChildFromArray(previousState, idsite, "idsite"))
				}

				if (activeSite == idsite){
					setActiveSite(0)
				}

				setRealSiteLength(prev => prev-1)
			}}
			addSelf={() => setRealSiteLength(prev => prev+1)}
			moveSelf={(siteToMove) =>{ 
				console.log("Calling moveSelf");
				moveSite(siteToMove);
				//removeSelf is also called if moveSelf is called, so realSiteLength doesn't need to be updated
			}}
		/>
	}

	if (realSiteLength > 0){
		return <>
			{name !== null ? <label className={"strong mt-4" + (isMobile ? " mb-2" : "")}>{name}</label> : null}
			<div className="row pb-4 mb-2">
				{sites.map((site, key) => {
					if (site) {
						return renderSiteDisplay(site, key)
					} else {
						return null
					}
				})}
			</div>
		</>
	} else return emptyState

}