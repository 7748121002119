import React, {Fragment, useState, useEffect} from 'react';
import TableData from './DisplayComponents/TableData'
import TableRow from './DisplayComponents/TableRow'
import _ from 'lodash'
import SiteTableRow from './SiteTableRow'
import {
	PublishSubscribe,
	//ApiService
	HaulCheckService,
	//userService
} from '../../services'
import {addChildToArray/*, removeChildFromArray, usePrevious*/} from '../../utils'

export default function ManagerTableSection({passedContact, updateSite, updateMaterial, moveSite, newSite, clearNewSite, hasExternalAccess, isMobileOnly, isMobile, isBrowser}){

	let [primaryContact, setPrimaryContact] = useState(_.cloneDeep(passedContact));
	let [sites, setSites] = useState(_.cloneDeep(passedContact.sites));
	let [realSiteLength, setRealSiteLength] = useState(primaryContact.sites.length);
	let [client, setClient] = useState(null);

	let setSubscription = async () => {
		let topic = "SI/sites/for/"+passedContact.username;

		client = await PublishSubscribe.quickSubscribe(
			"SI-frontend/MasterSetup/ManagerTableSection",
			topic,
			handleMessage
		)

		setClient(client);
	}

	useEffect(() => {
		setSubscription()

		return(() => {
			if (client !== null){
				PublishSubscribe.closeClient(client, "SI-frontend/MasterSetup/ManagerTableSection");
			}
		})
	}, [])

	let handleMessage = async (message) => {
		message = JSON.parse(message);

		if (message.type == "new-site"){
			let site = message.site;

			if (site.mayHaveChildren){
				site.materials = await HaulCheckService.getHCMaterialsForSidebarSite(site.idsite, true);
			} 

			setSites((previousState) => {return addChildToArray(previousState, site, "idsite")})
			setRealSiteLength(prev => prev+1)
		}
	}

	if (realSiteLength == 0) return null;
	return <div className="mst-col">

		{ !_.isEmpty(sites) ? sites.map((site, index) => {
			return <SiteTableRow
				passedSite={site}
				updateSite={(typeOfUpdate) => updateSite(site, typeOfUpdate)}
				updateMaterial={updateMaterial}
				key={site.idsite}
				hasExternalAccess={hasExternalAccess}
				removeSelf={() => {return null}}
				passedManagerName={primaryContact.contactName}
				passedManagerUsername={primaryContact.username}
				removeSelf={(idsite) => {
					setRealSiteLength(prev => prev-1)
				}}
				addSelf={() => setRealSiteLength(prev => prev+1)}
				moveSelf={(siteToMove) =>{ 
					console.log("Calling moveSelf");
					moveSite(siteToMove);
					//removeSelf is also called if moveSelf is called, so realSiteLength doesn't need to be updated
				}}
			 />
		}) : null}
	</div>

}