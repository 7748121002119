import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import _ from 'lodash';

//import App from './App';
import {BrowserRouter, Route, Redirect, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// Components
import UserSettings from './components/UserSettings'
import HaulChecks   from './components/HaulChecks';
import WelcomeToHaulcheck from './components/Event/WelcomeToHaulcheck'
import Matches      from './components/Matches';
import SiteMaterials from './components/SiteMaterials';
import CompanyImbalances from './components/CompanyImbalances';
import Road      from './components/Road';
import Setup        from './components/Setup';
import MySites   from './components/MySites';
import SiteArchive from './components/SiteArchive';
import MasterSetup from './components/MasterSetup';

import Login from './components/Login'
import Invite from './components/Invite'
import RegisterCore from './components/Register/RegisterCore'
import VerifyRegisterCore from './components/Register/VerifyRegisterCore'
import LogoutCore from './components/Logout/LogoutCore';

import Analytics from './components/Analytics'
import StateMap from "./components/SandboxMap/StateMap"

import Checkout from './components/Checkout'
import CheckoutDetails from './components/Checkout/CheckoutDetails'

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import jwtDecode from 'jwt-decode';
import { url } from './constants'

import './assets/css/page.min.css';
import './assets/css/style.min.css';
import './assets/css/ei-style.css';

const mySitesPathParams = ["", "/:params", "/:secondaryDisplayState"]

const LoggedIn =(
  <BrowserRouter>
    <Switch>
      <Route path="/analytics/tech"               render = {(props) => <Analytics mode="tech" match={props.match} history={props.history} />} />
      <Route path="/analytics"                    render = {(props) => <Analytics mode="marketing" match={props.match} history={props.history} />} />
      <Route path="/user-settings"                component={ UserSettings } />
      <Route path="/welcome"                      component={ WelcomeToHaulcheck } />
      {/*<Route path="/haul-check-sites"           	component={ HaulChecks } />*/}
      <Route path="/spare-iron-invite/post"      render = {(props) => <Invite type="blast" match={props.match} history={props.history} />} />
      <Route path="/spare-iron-invite/network"   render={(props) => <Invite type="standard" history={props.history} /> } />
      <Route path="/site-materials"               component={ SiteMaterials } />
      <Route path="/matches-inbox"              	component={ Matches } />
      {/*<Route path="/company-imbalances"           component={ CompanyImbalances } />*/}
      <Route path="/road"      	                  component={ Road } />
      <Route path="/resource-check-setup"                         component={ Setup } />
      <Route path="/app/:passedDisplayState/:params"  render={(props) => <MySites match={ _.cloneDeep(props.match) } history={props.history} location={props.location} />} />
      <Route path="/app/:passedDisplayState"          render={(props) => <MySites match={ _.cloneDeep(props.match) } history={props.history} location={props.location} />} />
      <Route path="/checkout"                     component={ Checkout } />
      <Route path="/checkout-details"                     component={ CheckoutDetails } />
      <Route path="/spare-iron-site-archive"      component={ SiteArchive } />
      <Route path="/master-setup"                 component={ MasterSetup } />
      <Route path="/state-map"                       render = {(props) => <StateMap {...props} /> } />

      <Route path="/logout"                       render = {(props) => <LogoutCore home={url.frontend} /> } />
      <Redirect from="/" to="/app/sites/my-sites" />
    </Switch>
  </BrowserRouter>
)

const LoggedOut = (
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" render={(props) => <RegisterCore home={url.frontend} logo="/img/logo/si-logo.png" /> } />
      <Route path="/account-confirmation" render={(props) => <VerifyRegisterCore home={url.frontend} logo="/img/logo/si-logo.png" /> } />
      <Redirect to="/login" />
    </Switch>}
  </BrowserRouter>
)

Amplify.configure(awsconfig);

Auth.currentAuthenticatedUser().then(async user => {
    console.log("user:", user);
    const userJWT = user.signInUserSession.idToken.jwtToken;
    
    if(userJWT!==null){
      localStorage.setItem('token', userJWT);
    }

    ReactDOM.render(LoggedIn, document.getElementById('root'));

}).catch(err => {
  if (err !== "not authenticated"){
      console.log("err:", err);
    } else {
      ReactDOM.render(LoggedOut, document.getElementById('root'));
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
