import React, { Component, Fragment } from 'react'
import {
  ApiService,
  PMGroupsService } from '../../../services';
import PmForm from './Form';
import SuccessPopup from '../Popups';

export default class PMGroup extends Component {

	constructor(props){
    	super(props);

    	this.state = {
    		email: ApiService.getJWTEmail(),
    		idpmgroup: null,
    		pmGroupName: null,
    		showModal: false,
    		popupTriggerUsersAdded: false,
            popupTriggerUsersRemoved: false,
            popupTriggerUsersUpdated: false,
            popupTriggerGroupCreated: false,
            rejectedUsers: [],
    	};

	}

    componentWillMount = async() => {
        let {pmGroup} = this.props;

        let idpmgroup = null;
        let pmGroupName = null;

        if (pmGroup != null){
            idpmgroup = pmGroup.idpmgroup;
            pmGroupName = pmGroup.name;
        }

        this.updatePmGroup(idpmgroup, pmGroupName);
    }

	componentWillReceiveProps = async ({pmGroup}) => {
		let idpmgroup = null;
		let pmGroupName = null;

		if (pmGroup != null){
			idpmgroup = pmGroup.idpmgroup;
			pmGroupName = pmGroup.name;
		}

		this.updatePmGroup(idpmgroup, pmGroupName);
	}

	updatePmGroup = (idpmgroup, pmGroupName, useCallback = false) => {
        let {callback} = this.props;
		this.setState({idpmgroup, pmGroupName});
        if (callback && typeof callback=="function" && useCallback) callback({idpmgroup, pmGroupName});
	}

    updateRejectedUsers = (rejectedUsers) => {
        this.setState({rejectedUsers});
    }

	render = () => {
		let {idpmgroup, rejectedUsers} = this.state;
        let {simpleMode} = this.props;
        if (idpmgroup === false) return null;
		return(<Fragment>
			<div className="col-12 border px-4 py-2 mx-auto mt-4">
                <div className="col-12 mr-auto alert-light p-1">
					{idpmgroup ? this.renderHasPmGroup() : this.renderNewPmGroupComponents()}

                    {rejectedUsers.length > 0 ? <Fragment>
                        <div className="alert alert-warning mt-4 mb-0" role="alert">
                            <button type="button" className="close" onClick={() => {this.setState({rejectedUsers: []})}} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <p>The following users are part of a different office group and could not be added: </p>
                            <ul>
                                {rejectedUsers.map((email, index) => { return(
                                    <li key={index}>{email}</li>
                                )})}
                            </ul>

                            
                        </div>
                    </Fragment> : null}

				</div>
			</div>
			{this.renderPopups()}
	    </Fragment>);
	}

	renderHasPmGroup = () => {
		let {pmGroupName, modalIsOpen} = this.state;
		return(<Fragment>
			<p><i className="fa fa-check text-success strong mr-2"></i>You belong to the office group <em>{pmGroupName}</em></p>
			<button className="btn btn-xs btn-outline-primary" 
                data-toggle="modal" 
                data-target="#edit-group"
                onClick={() => this.setState({showModal: !this.state.showModal})}>
                    Manage your Office Group
            </button>

            <PmForm
                closeModal={this.closeModal}
                updatePmGroup={this.updatePmGroup}
                newGroup={false}
                openPopup={this.openPopup}
                idpmgroup={this.state.idpmgroup}
                updateRejectedUsers={this.updateRejectedUsers}
                showModal={this.state.showModal}
            />

	    </Fragment>);
	}

	renderNewPmGroupComponents = () => {
		let {modalIsOpen} = this.state;
		return(<Fragment>
            <p class="mb-2">Start an office group within your company to compare imbalances.</p>
            <button class="btn btn-xs btn-primary" 
                data-toggle="modal" 
                data-target="#edit-group"
                onClick={() => this.setState({showModal: !this.state.showModal})}>Start an Office Group
            </button>

            <PmForm
                closeModal={this.closeModal}
                updatePmGroup={this.updatePmGroup}
                updateRejectedUsers={this.updateRejectedUsers}
                openPopup={this.openPopup}
                idpmgroup={null}
                newGroup={true}
                showModal={this.state.showModal}
            />
	    </Fragment>);
	}

	closeModal = () => {
		this.setState({modalIsOpen: false});
	}

	openPopup = (popupId) => {
		console.log("Calling openPopup with id " + popupId);
		this.setState({[popupId]: true, modalIsOpen: false});
	}

	closePopup = (popupId) => {
		console.log("Calling closePopup with id " + popupId + "; openPopup");
		this.setState({[popupId]: false})
	}

	renderPopups = () => {return(<Fragment>
		 <SuccessPopup
            popupId="popupPMGroupAdded"
            title="Success!"
            message="Group created!"
            callback={() => this.closePopup("popupTriggerGroupCreated")}
            trigger={this.state.popupTriggerGroupCreated}
             />
        <SuccessPopup
            popupId="popupPMUsersAdded"
            title="Success!"
            message="Users added!"
            callback={() => this.closePopup("popupTriggerUsersAdded")}
            trigger={this.state.popupTriggerUsersAdded}
             />
        <SuccessPopup
            popupId="popupPMUsersRemoved"
            title="Success!"
            message="Users removed!"
            callback={() => this.closePopup("popupTriggerUsersRemoved")}
            trigger={this.state.popupTriggerUsersRemoved}
             />
        <SuccessPopup
            popupId="popupPMUsersUpdated"
            title="Success!"
            message="Users updated!"
            callback={() => this.closePopup("popupTriggerUsersUpdated")}
            trigger={this.state.popupTriggerUsersUpdated}
             />
	</Fragment>)}

}