export function hcDisplayPrice(price, showDecimalsIfWholeNumber = true){

	if (!price && price !== 0) return "";

	price = parseInt(price);

	let isWholeNumber = (price % 100 == 0);

	console.log("hcDisplayPrice price: ",price,"isWholeNumber: ",isWholeNumber);
	let toReturn = "$";

	if (isWholeNumber){
		if (showDecimalsIfWholeNumber){
			toReturn += (price/100).toFixed(2);
		} else {
			toReturn += (price/100).toFixed(0);
		}
	} else {
		toReturn += (price/100).toFixed(2);
	}

	return toReturn;
}