//Depreciated

import React, {useState, useEffect, useLayoutEffect, Fragment}  from 'react';
import moment from 'moment';
import OptButton from '../SiteMaterials/OptInToPayment/OptButton'
import SuccessPopup from '../common/Popups'
//import SharedDetailsDisplay from '../common/SharedDetailsDisplay'
import {HaulCheckService} from '../../services'
import ModalShareDetails from '../Conversations/ModalShareDetails';
import ArchiveMaterial from '../MySites/Sidebar/SettingsModals/ArchiveMaterial';
import DeleteAMaterial from '../common/DeleteAMaterial';
//import MatchCriteriaRow from './MatchCriteriaRow'
import { Popover, OverlayTrigger } from 'react-bootstrap';
import {moveElementToBody, hcGetIconForSiteOrMatch, hcDetailsDisplay} from '../../utils'
import {useMySitesPanelChange} from '../MySites/useMySitesPanelChange'
//import useMatchCriteria from "../FormHooks/useMatchCriteria"

import './style.css'

export default function MyMaterial({material, toggleStatus, openModalInvite, getMaterials, toggleSharedDetailsReload, isBrowser, isMobile, backToSandbox, refreshPanel, tallyData}){

	let [quantityPopupTrigger, setQuantityPopupTrigger] = useState(false);
	let [idquantity, setidquantity] = useState(material.idquantity);
	const mySitesPanels = useMySitesPanelChange();

	useLayoutEffect(() => {
		if (isMobile) moveElementToBody("details-form-"+material.idsite_resource+"-myMaterial")
		moveElementToBody("popupDetailsUpdated")
		//loadMatchCriteriaMaterial(material.idsite_resource, "MyMaterial")
	}, [])

	/*let {
		loadMatchCriteriaMaterial,
		matchCriteriaFormPieces
    } = useMatchCriteria({
		onSubmit: () => {
			console.log("MaterialInfo Loading","calling match criteria onload")
			//setCriteriaSubmitted(true)
		},
		//onLoad: () => setCriteriaLoading(false)

	});

	let {renderMatchCriteriaMaxDistance, 
        renderMatchCriteriaSubmit,
        matchCriteriaQuantityString
    } = matchCriteriaFormPieces;*/

	let availableRange = () => {
		if (material.available_date){
			let start =  moment(material.available_date).add('days', 1).startOf("month");
			let endDate = (material.available_end_date ? material.available_end_date : material.available_date);
			let end = moment(endDate).add('days', 1).endOf("month");

			return start.format("MMM") + " - " + end.format("MMM")

		} else {
			return ""
		}
	}

	let renderBadge = () => {
		let {archived, archived_date, iddiscoverymode, status, has_ever_been_external, expiration_date} = material;


		if (archived && archived_date){
			return <span className="badge badge-pale badge-secondary">Archived — {moment(archived_date).format("M/D/YY")}</span>;
		} else if (has_ever_been_external && status == "internal" && iddiscoverymode == 1){
      		return <span className="badge badge-pale badge-warning">Search Expired {moment(expiration_date).format("M/D/YY")}</span>
	 	} else if (has_ever_been_external && status == "internal" && iddiscoverymode == 2){
      		return <span className="badge badge-pale badge-warning">Post Expired {moment(expiration_date).format("M/D/YY")}</span>
		} else if (status == "external" && iddiscoverymode == 2 ){
			return <span className="badge badge-pale badge-primary">Posted until {moment(expiration_date).format("M/D/YY")}</span>
			//return <span className="badge badge-pale badge-info">Posted</span>
		} else if (status == "external" && iddiscoverymode == 1){
			return <span className="badge badge-pale badge-primary">Bid Search until {moment(expiration_date).format("M/D/YY")}</span>
			//return <span className="badge badge-pale badge-info">Posted</span>
		} else if (iddiscoverymode == 1){
			return <span className="badge badge-pale badge-secondary">Bid</span>
		} else {
			return <span className="badge badge-pale badge-secondary">Not Posted</span>	
		}
	}

	let renderQuantity = () => {
		let {details, quantity} = material;
		let q = quantity;
		let displayS = true;

		/*if (details != undefined && details.length > 0){ 
			if (!details[0].quantitynoinfo && (details[0].quantity || details[0].quantitydetail)){
				q = details[0].quantitydetail || details[0].quantity; 
				displayS = false;
			}
		}*/

		return parseInt(q).toLocaleString() + (displayS ? "s " : " ") + "CYs";
	}

	let modals = (param) => {
		console.log("MyMaterials modals with param ", param)

		return <>
			<ArchiveMaterial idsite_resource={material.idsite_resource} idsite={material.idsite} callback={backToSandbox} />
			<DeleteAMaterial material={material} callback={backToSandbox} />
			<ModalShareDetails idpmgroup={material.idpmgroup} idsite_resource={material.idsite_resource} parent="myMaterial" forceReload={refreshPanel} callback={refreshPanel} />

			 <SuccessPopup
	        	trigger={quantityPopupTrigger}
	        	popupId="confirm-successful-quantity-change"
	        	style="success"
	        	useButton={false}
	        	buttonLabel="Yes"
	        	title={"Quantity Updated"}
	        	message={"Quantity Successfully Updated"}
	        	callback={() => setQuantityPopupTrigger(false)}
	        />

		</>
	}

	let matchCriteriaRow = () => {
		/*return <MatchCriteriaRow
			material={material}
			callback={refreshPanel}
			isMobile={isMobile}
			isBrowser={isBrowser}
		/>*/
		return null;
	}

	let updateQuantity = (val) => {
		setidquantity(val)
		HaulCheckService.updateQuantity({idsite_resource: material.idsite_resource, idquantity: val});
		setQuantityPopupTrigger(true);
	}

	let renderAvailability = () => {
		return hcDetailsDisplay("availability", material.details, {
			emptyState: "No specific dates",
			dateFormat: "M/D/YY",
		})
	}

	let renderCost = () => {
		return hcDetailsDisplay("cost", material.details, {
			emptyState: "No cost",
			per: " /",
		})
	}

	/*let renderQuantity = () => {
		return hcDetailsDisplay("quantity", material.details, {
			emptyState: "No specific qty",
		})
	}*/

	let renderTrucking = () => {
		return hcDetailsDisplay("trucking", material.details, {
			emptyState: "No trucking info",
		})
	}

	let renderDescription = () => {
		return hcDetailsDisplay("description", material.details, {
			emptyState: "No additional description",
		})
	}

	let renderContact = () => {
		return (material.partnerName ? "Contact " + material.partnerName : "No contact on file")
	}

	let renderIsSuperBlast = () => {
	    let {isBlasted} = material;
	    
	    if (isBlasted == 1){
	      return <small><span className="badge badge-success"><em><strong>SuperBlast</strong></em></span></small>
	    }
	    else return null;
	  }

	if (material == null) return null;

	else{

		let {name, isBlasted, quantity, resource, type, archived, archived_date, partnerName, details, idsite_resource} = material;

		if (type == "Surplus/Export"){
			type = "Surplus"
		} else {
			type = "Short"
		}

		if (isBrowser){

	        return(<>

				{modals("browser")}		       

	    		{/*Main detail header*/}

	    		<div class="mb-0" style={{position: 'sticky',top: '0px',zIndex: 500,backgroundColor: '#fff'}}>
					<div class="ml-2 p-2">
						<div class="clearfix">
							<figure class="marker float-left mr-2">
								<img src={hcGetIconForSiteOrMatch(material, "myMaterial")} />
							</figure>
							<p class="fw-600">{name}</p>
						</div>
						<div class="border-bottom clearfix">
							<p class="lead-4 pt-2 mb-0 float-left">{resource} {type} {renderQuantity()} {renderIsSuperBlast()}</p>

							<div class="clearfix">

								<div class="text-right float-right ml-4 mt-4">
									<div class="dropdown">
										<span class="dropdown-toggle no-caret" data-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
										<div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{position: 'absolute', transform: 'translate3d(-157px, 24px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
												<a className="dropdown-item" href="#" data-toggle={"popup"} data-target={"#archive-material"}><i className="ti ti-archive mr-1" aria-hidden="true"></i> Archive Material</a>
												<a className="dropdown-item text-danger" href="#" data-toggle={"popup"} data-target={"#deletematerial"}><i className="ti ti-trash mr-1" aria-hidden="true"></i> Delete Material</a>
										</div>
									</div>
								</div>

								<button className="rounded-pill btn btn-xs btn-primary mt-2 float-right" onClick={() => mySitesPanels.add("material-info")}>Material Details</button>


								{/*<OverlayTrigger trigger="hover" placement="right" overlay={
				                    <Popover>
				                      <Popover.Header as="h3">Quantity Order of Magnitude</Popover.Header>
				                      <Popover.Body>
				                       This is for informational purposes only and will be made visible to all your matches. It will not change your match results.
				                      </Popover.Body>
				                    </Popover> 
	                			}>
									<a tabindex="0" className="small text-secondary cursor float-right mr-4">
										<i className="fa fa-info-circle" aria-hidden="true"></i>
									</a>
								</OverlayTrigger>

								<div className="form-group input-line px-0 mr-2 pt-2 mb-3 float-right">
									<select className="lead-2 form-control pt-0 pr-2 pb-0" value={idquantity} onChange={(e) => updateQuantity(e.target.value)}>
										<option className="pb-0" value="3">10,000s CYs</option>
										<option className="pb-0" value="2">1,000s CYs</option>
										<option className="pb-0" value="1">100s CYs</option>
									</select>
								</div>*/}

							</div>

						</div>
					</div>
				</div>

				{/*matchCriteriaRow()*/}

				{/*<div class="px-4">

					<div>
						<label class="fs-14">Material Details</label>
					</div>

					<div class="card border bg-light shadow-1 pt-2 pr-2 pb-4 mb-4">
						<div class="card-body px-4 pt-1 pb-0">
							<div class="row pt-1 pb-0">


								<div class="col-4 lh-2">
									<span class="small">{renderAvailability()}</span>
								</div>

								<div class="col-2 lh-2 text-right">
									<span class="small">{renderQuantity()}</span>
								</div>

								<div class="col-3 lh-2 text-right">
									<span class="small">{renderTrucking()}</span>
								</div>

								<div class="col-3 lh-2 text-right">
									<label class="small">{renderCost()}</label>               
								</div> 

								<div class="col-12 lh-2">
									<label class="small">{renderDescription()}</label>                        
								</div>                            

								<div class="col-6 lh-2 pr-1">
									<span class="small">{renderContact()}</span>
								</div>

								<div class="col-6 lh-2 pr-1 pb-1 text-right">
									<button class="btn btn-xs btn-round btn-primary float-right" data-toggle={"modal"} data-target={"#details-form-"+material.idsite_resource+"-myMaterial"}>Edit</button>
								</div>


							</div>
						</div>
					</div>

				</div>*/}

				{/*End main detail header*/}

	    	</>)
		} else if (isMobile){
			return(<>

				{modals("mobile")}

				<div class="mb-0">
					<div class="p-2">
						<div class="clearfix">
							<figure class="marker float-left mr-2">
								<img src={hcGetIconForSiteOrMatch(material, "myMaterial")} />
							</figure>
							<p class="fw-600">{name}</p>
						</div>
						<div class="border-bottom clearfix mb-4">
							<p class="lead-4 float-left mb-0">{resource} {type} {renderQuantity()}</p>
							
							<div class="text-right float-right ml-4 mt-2">
								<div class="dropdown">
									<span class="dropdown-toggle no-caret" data-toggle="dropdown"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
									<div class="dropdown-menu dropdown-menu-right">
										<a class="dropdown-item" href="#" data-toggle={"popup"} data-target={"#archive-material"}><i class="ti ti-archive mr-1" aria-hidden="true"></i> Archive Material</a>
										<a class="dropdown-item text-danger" href="#" data-toggle={"popup"} data-target={"#deletematerial"}><i class="ti ti-trash mr-1" aria-hidden="true"></i> Delete Material</a>
									</div>
								</div>
							</div>

							<button className="rounded-pill btn btn-lg btn-primary mt-2 float-right" onClick={() => mySitesPanels.add("material-info")}>Material Info</button>
							
							
							<br/><br/>

							<OverlayTrigger trigger="hover,focus" placement="right" overlay={
			                    <Popover>
			                      <Popover.Header as="h3">Quantity Order of Magnitude</Popover.Header>
			                      <Popover.Body>
			                       This is for informational purposes only and will be made visible to all your matches. It will not change your match results.
			                      </Popover.Body>
			                    </Popover> 
                			}>
								<a tabindex="0" class="small text-secondary cursor float-right ml-2 mr-0" data-toggle="popover" data-trigger="hover">
									<i class="fa fa-info-circle" aria-hidden="true"></i>
								</a>
							</OverlayTrigger>

							{/*<div class="col-11 form-group input-line px-0 ml-2 mb-4 float-right">
								<select class="lead-2 form-control pt-0" value={idquantity} onChange={(e) => updateQuantity(e.target.value)}>
									<option className="pb-0" value="3">10,000s CYs</option>
									<option className="pb-0" value="2">1,000s CYs</option>
									<option className="pb-0" value="1">100s CYs</option>
								</select>
							</div>

							<button class="btn btn-block btn-xs btn-round btn-primary" data-toggle={"modal"} data-target={"#details-form-"+material.idsite_resource+"-myMaterial"}><i class="ti ti-plus" aria-hidden="true"></i> Add Details</button>*/}
						</div>

					</div>
		        </div>

		        {/*matchCriteriaRow()*/}

				{/*<div class="bg-light border pt-1 px-2 mt-4">

					<div class="px-2">
						<label class="fs-14">Material Details</label>
					</div>

					<div class="card border shadow-1 pt-2 pr-2 pb-4 mb-4">
						<div class="card-body px-4 pt-1 pb-0">
							<div class="row pt-1 pb-0">


								<div class="col-8 lh-2">
									<a class="small">{renderAvailability()}</a>
								</div>

								<div class="col-4 lh-2 text-right">
									<a class="small">{renderQuantity()}</a>
								</div>

								<div class="col-8 lh-2">
									<a class="small">{renderTrucking()}</a>
								</div>

								<div class="col-4 lh-2 text-right">
									<label class="small">{renderCost()}</label>               
								</div> 

								<div class="col-12 lh-2 pt-2">
									<label class="small">{renderDescription()}</label>                        
								</div>          

								<div class="col-8 lh-2 pr-1 mt-3">
									<a class="small">{renderContact()}</a>
								</div>

								<div class="col-4 lh-2 pr-1 mt-1 text-right">
									<button class="btn btn-xs btn-round btn-primary" data-toggle={"modal"} data-target={"#details-form-"+material.idsite_resource+"-myMaterial"}>Edit</button>
								</div>

							</div>
						</div>
					</div>

				</div>*/}

			</>)
		}

	}
}
