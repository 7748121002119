import { url, earthidealAPIURL } from '../constants'

export const ConversationsService = {
  getMessagesCount,
  getAllMessages,
  addNewConversation,
  updateMessagesStatus,
  getMatchData
}

async function getMessagesCount(id) {
  const resp = await fetch(`${url.backend}/conversations/countmessages/${id}`).then(handleResponse)
  //console.log('Messages from', id, ':', resp.messagesCount)
  return resp.messagesCount
}

async function getAllMessages(id) {
  const resp = await fetch(`${url.backend}/conversations/getallmessages/${id}`).then(handleResponse)
  return resp
}

async function getMatchData(idmatchsiteresource) {
  const resp = await fetch(`${url.backend}/matches/getMatchData/${idmatchsiteresource}`).then(handleResponse)
  //console.log("getMatchData resp:", resp);
  return resp.data;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
async function addNewConversation(newConversation){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      newConversation
    })
  }

  const resp = await fetch(`${url.backend}/conversations/addmessage`, requestOptions)
      .then(handleResponse)

  return resp
}
async function updateMessagesStatus(newConversation){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      newConversation
    })
  }
  const resp = await fetch(`${url.backend}/conversations/updateMessagesStatus`, requestOptions)
      .then(handleResponse)

  return resp
}


