import React, {Fragment} from 'react';
import ReactDOMServer from 'react-dom/server';
import H from "@here/maps-api-for-javascript";
import polyUtil from 'polyline-encoded';
import poly from '../../constants/polyline'
import Dialog from './Dialog';
import NewSite from './NewSiteDialog';
import './homepage.css'
import _ from 'lodash';
import {hcGetIconForSiteOrMatch} from '../../utils'
import {PublishSubscribe} from '../../services'
import JwtDecode from 'jwt-decode'

export default class MapContainer extends React.Component {


  constructor(props) {
    super(props);
    // the reference to the container
    this.ref = React.createRef()
    // reference to the map
    this.map = null;
    this.ui = null;
    this.sharedSiteMarker = null;
    this.markers = null;
    this.platform = null;

    let loggedInUsername = ""
    const userJWT = localStorage.getItem('token')

    if (userJWT !== "null" && !!userJWT && userJWT !== undefined && userJWT !== null ){ //This is genuinely supposed to look for a string with the value null, not actual null
      let userDecoded = JwtDecode(userJWT);
      loggedInUsername = (userDecoded ? userDecoded['cognito:username'] : "");
    }

    console.log("hcGetIconForSiteOrMatch loggedInUsername:", loggedInUsername)

     const defaultCenter = {
        lat: 43.038902, lng: -87.906471,
      }

    this.state = {
        tempMarker: null,
        tempBubble: null,
        showNewSiteModal: false,
        showInfoWindow: false,
        zoomAmount: 13,

        selectedSite: null,
        activeMarker: null,
        location: JSON.parse(JSON.stringify(defaultCenter)),
        defaultCenter,
        clickingOnMarker: false,

        mapInstance: null,
        activeBubble: null,
        activeOriginBubble: null,
        activeRouteBubblesIds: [],
        activeRouteBubbles: [],
        numMapMarkers: 0,
        currentlyRenderedRoutes: [],
        loggedInUsername,

        mapPinIcon: new H.map.Icon('<svg height="19" width="19" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="7" stroke="white" fill="#1b468d" /></svg> '),

        groupSitesMarkerIcon: new H.map.Icon('<svg width="24" height="24" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
          'height="22" /><text x="12" y="18" font-size="12pt" ' +
          'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
          'fill="white">G</text></svg>'),

        regularSiteMarkerIcon: new H.map.Icon('<svg width="24" height="24" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
          'height="22" /><text x="12" y="18" font-size="12pt" ' +
          'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
          'fill="white"></text></svg>'),

        sharedSiteMarkerIcon: new H.map.Icon('<svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">'+
        '<rect stroke="white" fill="#025e25" x="18" y="-12" width="22" height="22" transform="rotate(45)"/></svg>'),

        routeColors: [
          'rgba(0, 0, 102, 0.7)',
          'rgba(0, 0, 102, 0.7)',
        ]
    }
  }

  async componentDidMount() {
    //console.log("Ghostly Overhang Debug", "calling componentDidMount", "==========================================================================");
    let {zoomAmount, defaultCenter} = this.state;
    if (!this.ref){
      this.ref = React.createRef();
    }

    if (!this.map) {
      // instantiate a platform, default layers and a map as usual
      const platform = new H.service.Platform({
        apikey: process.env.REACT_APP_HERE_API_KEY
      });

      //console.log("H.service:", H.service);

      const layers = platform.createDefaultLayers();
      const map = new H.Map(
        this.ref.current,
        layers.vector.normal.map,
        {
          pixelRatio: window.devicePixelRatio,
          bounds: this.getBoundsFromDefaultCoords()
        },
      );

      const ui = H.ui.UI.createDefault(map, layers);
      ui.setUnitSystem(H.ui.UnitSystem.IMPERIAL);
      //let zoom = ui.getControl("zoom");
      //console.log("Zoom:", zoom);
      let zoom = new H.ui.ZoomControl({
        fractionalZoom: false,
        zoomSpeed: 1
      })

      ui.removeControl("zoom");
      ui.addControl("zoom", zoom);
      const router = platform.getRoutingService(null, 8);
      const markers = new H.map.Group();

      var mapEvents = new H.mapevents.MapEvents(map);

      // Add event listeners:
      map.addEventListener('tap', this.onClickMap);
      //map.addEventListener('mapviewchange', this.onZoomMap);

      // add the interactive behaviour to the map, disabling double-tap to zoom as well
      var behavior = new H.mapevents.Behavior(mapEvents);
      behavior.disable(H.mapevents.Behavior.DBLTAPZOOM);

      this.map = map;
      this.ui = ui;
      this.router = router;
      this.markers = markers;
      this.platform = platform;
      this.behavior = behavior;

      this.map.addObject(this.markers);
    }

    await this.generateIcons();


    if (this.props.sharedRoutes){
      await this.renderSharedRoutes(this.props.sharedRoutes, this.props.originSite, false);
    } else if (this.props.mySitesList.length > 0 || this.props.hcGroupSites.length > 0 || this.props.promotionalSites.length > 0) {
      await this.renderMySitesMarker(this.props.mySitesList, false);
      await this.renderHCGroupSitesMarker(this.props.hcGroupSites, null, false);
      await this.renderPromotionalSitesMarker(this.props.promotionalSites, false)
    } else {
      this.zoomToDefaultCoords();
    }

    //Todo: Render sponsored sites and SuperBlast sites here

    window.addEventListener('resize', this.resizeMap);

    this.resizeMap();
  }


  zoomToDefaultCoords = () => {
    if ( false
        //_.isEmpty(this.props.mySitesList) 
        //&& _.isEmpty(this.props.hcGroupSites) 
        //&& !_.isEmpty(this.props.defaultCoords)
      ) {
  
      this.map.getViewModel().setLookAtData({
        bounds: this.getBoundsFromDefaultCoords()
      }, true);

      //this.map.getViewPort().setPadding(150, 150, 150, 150)
    }
  }

  getBoundsFromDefaultCoords = () => {
    if (!_.isEmpty(this.props.defaultCoords)){
       return new H.geo.Rect(
        this.props.defaultCoords[0].lat, 
        this.props.defaultCoords[0].lng, 
        this.props.defaultCoords[1].lat, 
        this.props.defaultCoords[1].lng
      )
    } else {
      return undefined;
    }
   
  }

  resizeMap = () => {
    this.map.getViewPort().resize();
  }

  generateIcons = async () => {
    let icons = hcGetIconForSiteOrMatch(null, "map");

    let newState = {};

    for (var i = 0; i < icons.length; i++){
      console.log("Generating icon:", icons[i]);

      if (this.state[icons[i].key] === undefined){
        newState[icons[i].key] = new H.map.Icon(icons[i].val, {size: {w: 25, h: 25}});
      }
    }

    console.log("generateIcons newState:", newState);
    await this.promisedSetState(newState);

    return;
  }

  promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

  /* static getDerivedStateFromProps = (nextProps, prevState) => {

        console.log("Calling getDerviedStateFromProps in SandboxWrapper with prevState.groupSitesList:", prevState.groupSitesList,
            "nextProps.groupSitesList", nextProps.groupSitesList, "prevState.sitesList:", prevState.sitesList, "nextProps.siteList", nextProps.sitesList)

        }

    }*/

  /*getDerivedStateFromProps(nextProps, prevState){
    let {sharedRoutes} = nextProps;
    let {currentlyRenderedRoutes} = prevState;

    if (sharedRoutes != null && sharedRoutes != []){
      console.log("polyline", "Rendering shared routes in general")
      console.log("polyline", "currentlyRenderedRoutes", currentlyRenderedRoutes);
      console.log("polyline", "nextProps.sharedRoutes", sharedRoutes)
      
      if (JSON.stringify(sharedRoutes) != JSON.stringify(currentlyRenderedRoutes)){
        console.log("polyline", "Shared routes are different; re-rendering");
       
       
        this.renderSharedRoutes(nextProps.sharedRoutes, nextProps.originSite, (currentlyRenderedRoutes == []));

        return {currentlyRenderedRoutes: nextProps.sharedRoutes};

      } else {
        //this.renderSharedRoutes(newProps.sharedRoutes, newProps.originSite, false); 
      }
    }
  }*/

  async componentDidUpdate(prevProps){
    console.log("SandboxCore componentDidUpdate prevProps:", prevProps.sharedRoutes);
    console.log("SandboxCore componentDidUpdate this.props:", this.props.sharedRoutes);
    console.log("SandboxCore componentDidUpdate props are equal:", _.isEqual(prevProps.sharedRoutes, this.props.sharedRoutes) )

    if (!_.isEqual(prevProps.sharedRoutes, this.props.sharedRoutes) && this.props.sharedRoutes && this.props.sharedRoutes.length != 0){
      console.log("SandboxCore componentDidUpdate RENDERING SHARED ROUTES");
      this.renderSharedRoutes(this.props.sharedRoutes, this.props.originSite);
    } else {
      console.log("SandboxCore componentDidUpdate NOT rendering shared routes");
    }

    console.log("SandboxCore componentDidUpdate autoBubble", "prevProps.activeSite:", prevProps.activeSite, "this.props.activeSite", this.props.activeSite);

    let numTimesTapped = 0;

    if (prevProps.activeSite && !this.props.activeSite){
      console.log("SandboxCore componentDidUpdate autoBubble", "No active site, just clear existing bubbles")
      this.clearActiveBubble();
    } else if (!prevProps.activeSite && this.props.activeSite){
      console.log("SandboxCore componentDidUpdate autoBubble", "going from no active site to active site, just open bubble")
      this.markers.forEach((marker) => {
        if (parseInt(marker.getData()) === parseInt(this.props.activeSite) && numTimesTapped === 0){
          marker.dispatchEvent('tap');
          numTimesTapped++;
        }
      })
    } else if (prevProps.activeSite && this.props.activeSite && parseInt(prevProps.activeSite) !== parseInt(this.props.activeSite) ){
      console.log("SandboxCore componentDidUpdate autoBubble", "switching bubbles")
      await this.clearActiveBubble();
      this.markers.forEach((marker) => {
        if (parseInt(marker.getData()) === parseInt(this.props.activeSite) && numTimesTapped === 0){
          marker.dispatchEvent('tap');
          numTimesTapped++;
        }
      })
    }

    if ((prevProps.activeSite && this.props.activeSite) && (prevProps.activeSite !== this.props.activeSite)){
     
    }

    if (!_.isEqual(prevProps.defaultCoords, this.props.defaultCoords)) {
      this.zoomToDefaultCoords();
    }
  }

  async componentWillReceiveProps(newProps) {

    let {numMapMarkers} = this.state;
    let totalSites = newProps.mySitesList.length + newProps.hcGroupSites.length;

    //console.log("New call to componentWillReceiveProps independent of goToAdSharedSite, shared site is ",newProps.adSharedSite);

    if (newProps.searchResult !== {} && JSON.stringify(newProps.searchResult) != JSON.stringify(this.props.searchResult)){

      if (this.state.tempMarker){
        this.deleteTempMarker();
      }

      let tempMarker = await this.addMarker({location_x: newProps.searchResult.lat, location_y: newProps.searchResult.lng}, "mapPinIcon", "pinBubble");
      this.setState({tempMarker, location: newProps.searchResult});

      setTimeout(() => {
        this.map.setCenter(newProps.searchResult);
      }, 300);
    }

    if (newProps.sharedRoutes != null && newProps.sharedRoutes != []){
     //do nothing from here for now
    } else {
      //console.log("polyline", "not rendering shared routes");

      if (this.props.adSharedSite == null && newProps.adSharedSite == null) {
          //console.log("Returning from componentWillRecieveProps without calling goToAdSharedSite")
      } else if (this.props.adSharedSite == null && newProps.adSharedSite != null){
          //console.log("Passed a shared site when previous shared site was null; calling goToAdSharedSite");
          this.goToAdSharedSite(newProps.adSharedSite);
          totalSites++;

      } else if (this.props.adSharedSite != null && newProps.adSharedSite != null){
          if (this.props.adSharedSite.location_x != newProps.adSharedSite.location_x || this.props.adSharedSite.location_y != newProps.adSharedSite.location_y){
              //console.log("New shared site different from old shared site; calling goToAdSharedSite");
              this.goToAdSharedSite(newProps.adSharedSite)
              totalSites++;

          } else {
              //console.log("Passed same prop as last time; not calling goToAdSharedSite");
              //console.log("shared newProps",newProps.adSharedSite);
              //console.log("shared old props", this.props.adSharedSite)
          }
     
      } else {
          //console.log("Something else happened with the shared site. Here are the params:");
          //console.log("shared newProps",newProps.adSharedSite);
          //console.log("shared old props", this.props.adSharedSite)
      }

      let forceRerender = (numMapMarkers < totalSites || (newProps.sharedRoutes == null && this.props.sharedRoutes != null));

      if ((JSON.stringify(this.props.mySitesList) != JSON.stringify(newProps.mySitesList)) || forceRerender){
        await this.renderMySitesMarker(newProps.mySitesList, null, false);
      }

      if (JSON.stringify(this.props.hcGroupSites) != JSON.stringify(newProps.hcGroupSites) || forceRerender){
        await this.renderHCGroupSitesMarker(newProps.hcGroupSites, null, false);
      }

      if (!_.isEqual(this.props.promotionalSites, newProps.promotionalSites)){
        await this.renderPromotionalSitesMarker(newProps.promotionalSites, false)
      }

    }
   
  }

  goToAdSharedSite(site){

    if (this.sharedSiteMarker){
      this.markers.removeObject(this.sharedSiteMarker);
      this.sharedSiteMarker = null;
    }

    this.sharedSiteMarker = this.addMarker(site, "sharedSiteMarkerIcon");

    let location = {
      lat: site.location_x,
      lng: site.location_y
    };

    setTimeout(() => {
      this.map.setCenter(location);
    }, 300);
  }

  centerMapOnMarkers = async () => {

      console.log("centerMapOnMarkers markers:", this.markers, "and numMapMarkers", this.state.numMapMarkers);

      if (this.state.numMapMarkers > 0 && _.isEmpty(this.props.defaultCoords)){
      
        this.map.getViewModel().setLookAtData({
          bounds: this.markers.getBoundingBox()
        }, true);

        this.map.getViewPort().setPadding(115, 115, 115, 115)
      }
  }

  renderSharedRoutes = async (routes, origin, fromZoom) => {

    console.log("Calling renderSharedRoutes with fromZoom " + fromZoom);

   // if (!fromZoom){
   //   this.map.setZoom(13);
   // }
    //this.map.getViewPort().setMargin(0);

    this.removeMarkersAndRoutesAndBubbles();

    //Remove all bubbles from the map also
    //this.ui.dispose();
    //let layers = this.platform.createDefaultLayers();
    //this.ui = H.ui.UI.createDefault(this.map, layers);

    //Add the origin marker and the actual routes
    await this.addMarker(origin, "selected", "originBubble");
    await this.renderSharedRoutesLoop(routes); 

    //Zoom the map to show the markers and 
    //console.log("fromZoom outside of check:", fromZoom);
    if (!fromZoom){
      this.centerMapOnMarkers()     
    }
  }

  renderSharedRoutesLoop = async (routes) => {
    let {routeColors} = this.state;
    let {idsite_resource} = this.props;
    let markers = this.markers; 

    //return new Promise((resolve, reject) => {
      for (var i = 0; i < routes.length; i++){
        let route = routes[i];

        //console.log("Ghostly Overhang Debug", "Calling renderSharedRoutesLoop with idsite_resource "+idsite_resource+", visibility "+route.visible+" and route", route);
        if (route.visible && (idsite_resource != undefined && route.idsite_resource == idsite_resource)){
          // Create the parameters for the routing request:
          //console.log("Ghostly Overhang Debug", "The above route passed the check and is being rendered");
  
            this.router.calculateRoute({
              routingMode: "fast",
              transportMode: "truck",
              origin: route.latitude_origin+","+route.longitude_origin,
              destination: route.latitude_destination+","+route.longitude_destination,
              return: "polyline",
              units: "imperial",
              spans: "truckAttributes",
            }, (result) => {
              console.log("renderSharedRoutes Router calculate route result:", result);
              //addRouteShapeToMap(style, result.routes[0]);
  
              result.routes[0].sections.forEach((section) => {
                let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
  
                // Create a polyline to display the route:
                let polyline = new H.map.Polyline(linestring, {
                  style: {
                    lineWidth: 4,
                    strokeColor: routeColors[route.internal_match]
                  }
                });
                  
                // Add the polyline to the map
                this.markers.addObject(polyline);
              });
  
            },
            //reject
            console.log("Could not render route")
          );
        }

        let markerType = "";
        if (route.internal_match == 0){
          markerType += "external";
        } else {
          /*if ((!route.manager && route.username == this.state.loggedInUsername) || (route.manager == this.state.loggedInUsername)){
            markerType += "my-"
          } else {
            markerType += "group-"
          }*/

          if (route.iddiscoverymode == 2){
            markerType += "construction"
          } else {
            markerType += "bid"
          }
          
        }

        //markerType += "surp"

        console.log("renderSharedRoutes Add route marker with data", route);
        await this.addMarker(route, markerType, "routeBubble");
      }

      //resolve();
    //});
  }

  onClickMap = async (evt) => {
   
    console.log("onClickMap")

    let {clickingOnMarker, tempMarker, tempMarkerBubble} = this.state;

    //console.log("onClickMap", "Map locked?", locked);
    if (!(evt.target instanceof H.map.Marker)){

      if (evt.currentPointer){

        if (tempMarker){
          this.deleteTempMarker();
        } else {

          let location = this.map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
          //console.log("Calculated location:", location);

          let tempMarker = await this.addMarker({location_x: location.lat, location_y: location.lng}, "mapPinIcon", "pinBubble")

          this.setState({tempMarker, location});
        }
      }

    }
  }

  onZoomMap = (evt) => {
    let newZoom = null;
    let oldZoom = null;

    if (evt.newValue && evt.newValue.lookAt){
      newZoom = (evt.newValue.lookAt.zoom)/*.toFixed(1)*/;
      oldZoom = (evt.oldValue.lookAt.zoom)/*.toFixed(1)*/;

      if (newZoom.toFixed(1) != oldZoom.toFixed(1) && oldZoom != 0){
        //console.log("Map zoomed with evt",evt);
        //console.log("Map was zoomed", "newValue: ", newZoom, "oldValue: ", oldZoom);

        //this.removeMarkersAndRoutesAndBubbles();

        let markerSize1 = this.markerSize(24, 24, newZoom);
        let markerSize2 = this.markerSize(22, 22, newZoom);
        let markerSize3 = this.markerSize(36, 36, newZoom)
        let textLocation = this.markerSize(-12, 18, newZoom);

        let groupSitesMarkerIcon = new H.map.Icon('<svg width="'+markerSize1.width+'" height="'+markerSize1.height+'" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<rect stroke="white" fill="#1b468d" x="1" y="1" width="'+markerSize2.width+'" ' +
          'height="'+markerSize2.height+'" /><text x="'+markerSize2.width/2+'" y="'+((markerSize2.height/2)*1.5) +'" font-size="'+markerSize1/2+'pt" ' +
          'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
          'fill="white">G</text></svg>')

        let regularSiteMarkerIcon = new H.map.Icon('<svg width="'+markerSize1.width+'" height="'+markerSize1.height+'" ' +
          'xmlns="http://www.w3.org/2000/svg">' +
          '<rect stroke="white" fill="#1b468d" x="1" y="1" width="'+markerSize2.width+'" ' +
          'height="'+markerSize2.height+'" /><text x="12" y="18" font-size="12pt" ' +
          'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
          'fill="white"></text></svg>')

        let sharedSiteMarkerIcon = new H.map.Icon('<svg width="'+markerSize3.width+'" height="'+markerSize3.height+'" xmlns="http://www.w3.org/2000/svg">'+
        '<rect stroke="white" fill="#025e25" x="'+textLocation.height+'" y="'+textLocation.width+'" width="'+markerSize2.width+'" height="'+markerSize2.height+'" transform="rotate(45)"/></svg>');

        this.setState({groupSitesMarkerIcon, regularSiteMarkerIcon, sharedSiteMarkerIcon})

       if (this.props.sharedRoutes){
          this.renderSharedRoutes(this.props.sharedRoutes, this.props.originSite, true);
        } else {
          this.renderMySitesMarker(null, true);
          this.renderHCGroupSitesMarker(null, null, true);
          this.renderPromotionalSitesMarker(null, null, true);
           if (this.props.adSharedSite){
            this.sharedSiteMarker = this.addMarker(this.props.adSharedSite, "sharedSiteMarkerIcon");
          }
        }
      }
    }
  }

  markerSize = (baseWidth, baseHeight, zoom) => {

    if (zoom >= 12.8 && zoom <= 13.2) {
        return {width: baseWidth, height: baseHeight};
    } else {
        let factor = 1;
        if (zoom > 13){
          factor = (zoom - 13) * 1.1;
        } else if (zoom < 13){
          factor = (1 / ((13 - zoom) * 2));
        }

        let width = baseWidth * factor;
        let height = baseHeight * factor;

        if (width > (baseWidth * 1.5)) 
            width = baseWidth * 1.5;
        if (height > (baseHeight * 1.5))
            height = baseHeight * 1.5;

        return { width, height }
    }    
  }

  renderMySitesMarker = async (sitesList, fromZoom) => {
    if (!sitesList){
      sitesList = this.props.mySitesList;
    }

    for (var i = 0; i < sitesList.length; i++){
      let site = sitesList[i];
      await this.addMarker(site, (site.iddiscoverymode == 1 ? "bid" : site.iddiscoverymode == 2 ? "construction" : "pending"))    
    }

    if (!fromZoom){
      this.centerMapOnMarkers()     
    }

    return;
  }

  renderPromotionalSitesMarker = async (sitesList, fromZoom) => {
    if (!sitesList){
      sitesList = this.props.promotionalSites;
    }

    for (var i = 0; i < sitesList.length; i++){
      let site = sitesList[i];
      await this.addMarker(site, "superblast")    
    }

    //if (!fromZoom){
    //  this.centerMapOnMarkers()     
   // }

    return;
  }

  renderHCGroupSitesMarker = async (sitesList, mySitesList, fromZoom) => {

    if (!sitesList){
      sitesList = this.props.hcGroupSites;
    }

    if (!mySitesList){
      mySitesList = this.props.mySitesList;
    }

    if (!fromZoom){
      let initCenterSite = null;

      if (!_.isEmpty(sitesList)){
        initCenterSite = sitesList[0];
      } else if (!_.isEmpty(mySitesList)){
        initCenterSite = mySitesList[0];
      }

      if (initCenterSite !== null){
        let location = {
          lat: initCenterSite.location_x,
          lng: initCenterSite.location_y
        };

        this.map.setCenter(location);

      }
    }

    let mySites = mySitesList.map(({idsite}) => {return idsite});

    for (var i = 0; i < sitesList.length; i++){
      let site = sitesList[i];
      if (mySites.indexOf(site.idsite) == -1){
        await this.addMarker(site, (site.iddiscoverymode == 1 ? "bid" : site.iddiscoverymode == 2 ? "construction" : "pending"));
      }
    }

    if (!fromZoom){
      this.centerMapOnMarkers()     
    }

    return;
  }

  componentWillUnmount = async () => {
    this.ref = null;

    console.log("Ghostly Overhang Debug", "Calling componentWillUnmount");

    if (this.map) {
      this.map.removeEventListener('tap', this.onClickMap);
      //this.map.removeEventListener('mapviewchange', this.onZoomMap);
      await this.removeMarkersAndRoutesAndBubbles();
      this.map.removeObjects(this.map.getObjects());
    }

    window.removeEventListener('resize', this.resizeMap);
  }

  removeMarkersAndRoutesAndBubbles = async () => {
    //console.log("Calling removeMarkersAndRoutesAndBubbles; including the word polyline for filtering purposes");
    //console.log("Ghostly Overhang Debug", "removeMarkersAndRoutesAndBubbles", "calling removeMarkersAndRoutesAndBubbles");
    this.ui.getBubbles().forEach(bub => this.ui.removeBubble(bub))

    if (this.markers) {
      //console.log("Ghostly Overhang Debug", "Calling markers.removeAll");
      this.markers.removeAll();
    }

    this.setState({
      activeMarker: null,
      activeBubble: null,
      tempMarker: null,
      numMapMarkers: 0
    })
  }

  deleteTempMarker = () => {
    let {tempMarker, tempMarkerBubble} = this.state;
    if (tempMarker){
      this.markers.removeObject(tempMarker);
    }

    if (tempMarkerBubble){
      tempMarkerBubble.close();
      tempMarkerBubble.removeEventListener('statechange', (evt) => this.pinBubbleStateChange(evt));
      this.ui.removeBubble(tempMarkerBubble);
    }

    this.setState({tempMarker: null, tempMarkerBubble: null});
  }

  addMarker = async ({name, location_x, location_y, idsite, username, haulSiteId, iddiscoverymode, time, distance, visible, idroutedistance}, markerType, specialBubble) => {
    let {numMapMarkers} = this.state;
    numMapMarkers++;
    await this.promisedSetState({numMapMarkers});

    let icon = this.state[markerType];

    console.log("addMarker map icon for markerType "+markerType+":", icon);

    // Create an icon, an object holding the latitude and longitude, and a marker:
    var coords = { lat: location_x, lng: location_y },
    marker = new H.map.Marker(coords, { icon });

    if (specialBubble == "routeBubble"){
      marker.addEventListener('tap', (evt) => this.addRouteBubble(evt, this.ui, name, time, distance, visible, idroutedistance, numMapMarkers));
      if (visible){
        setTimeout(() => {marker.dispatchEvent('tap')}, 100);
      }
    } else if (specialBubble == "originBubble"){
      marker.addEventListener('tap', (evt) => this.addOriginBubble(evt, this.ui, name));
      marker.dispatchEvent('tap');
    } else if (specialBubble == "pinBubble"){
      marker.addEventListener('tap', (evt) => this.addPinBubble(evt, this.ui, numMapMarkers));
      marker.dispatchEvent('tap');
    } else {

      console.log("SandboxDebug", "Adding marker for site " + name + " with idsite " + idsite + " and iddiscoverymode " + iddiscoverymode);
      let int_iddiscoverymode = parseInt(iddiscoverymode);

      if (int_iddiscoverymode !== 1 && int_iddiscoverymode !== 2){
        if (haulSiteId != undefined) idsite = haulSiteId;
        console.log("SandboxDebug", "Adding setup bubble for idsite " + idsite);
        marker.setData(idsite);
        marker.addEventListener('tap', (evt) => this.addSetupBubble(evt, this.ui, name, idsite, numMapMarkers));
      } else {
        console.log("SandboxDebug", "Adding regular bubble for idsite " + idsite);
        marker.setData(idsite);
        marker.addEventListener('tap', (evt) => this.addMarkerBubble(evt, this.ui, name));
      }

     
    }

    // Add the marker to the map and center the map at the location of the marker:
    //this.map.addObject(marker);
    this.markers.addObject(marker);

    return marker;
  }

  addPinBubble = (evt, ui, numMapMarkers) => {
    this.setState({clickingOnMarker: true});
    let {tempMarkerBubble} = this.state;

    if (tempMarkerBubble != null){
      tempMarkerBubble.close();
      tempMarkerBubble.removeEventListener('statechange', (evt) => this.pinBubbleStateChange(evt));
      this.setState({tempMarkerBubble: null});
    }

    var bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
      content: this.bubbleContent(numMapMarkers)
    });

    bubble.addEventListener('statechange', (evt) => this.pinBubbleStateChange(evt))

    ui.addBubble(bubble);

    //Add event bubble
    this.setState({clickingOnMarker: false, tempMarkerBubble: bubble}, () => {
      let button = document.getElementById('pinbubble-button-'+numMapMarkers);
      //console.log("BUG-864", "Button for routedistance " + idroutedistance + ":", button);

      if (button.onClick == null){
        //console.log("BUG-864", "Adding onClick listener for idroutedistance " + idroutedistance);
        button.onclick = () => {
          this.setState({showNewSiteModal: true});
        }
      }
    });

  }

  addOriginBubble = (evt, ui, name) => {
    this.setState({clickingOnMarker: true});

    let {activeOriginBubble} = this.state;
    if (activeOriginBubble != null){
      activeOriginBubble.close();
      this.setState({activeOriginBubble: null});
    } 

    activeOriginBubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
      content: this.bubbleContent(this.state.numMapMarkers, name /*+ "\n\nSTART"*/)
    });

    // show info bubble
    ui.addBubble(activeOriginBubble);
    this.setState({clickingOnMarker: false, activeOriginBubble});
    
  }

  addRouteBubble = (evt, ui, name, time, distance, visible, idroutedistance, numMapMarkers) => {
    console.log("addRouteBubble params:", name, time, distance, visible, idroutedistance);
    this.setState({clickingOnMarker: true});

    let {activeRouteBubblesIds, activeRouteBubbles} = this.state;
    let idIndex = activeRouteBubblesIds.indexOf(idroutedistance) 

    if (idIndex != -1){
      activeRouteBubbles[idIndex].close();
      activeRouteBubbles.splice(idIndex, 1);
      activeRouteBubblesIds.splice(idIndex, 1);
      this.setState({activeRouteBubbles, activeRouteBubblesIds});
    }

    var bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
      content: this.bubbleContent(numMapMarkers, name, time, distance, visible, idroutedistance)
    });

    //bubble.addEventListener('statechange', (evt) => this.bubbleStateChange(evt))
    // show info bubble
    ui.addBubble(bubble);
    activeRouteBubbles.push(bubble);
    activeRouteBubblesIds.push(idroutedistance);
    this.setState({clickingOnMarker: false, activeRouteBubbles, activeRouteBubblesIds}, () => {
      let button = document.getElementById('show-route-'+idroutedistance+'-'+numMapMarkers);
      console.log("BUG-864", "Button for routedistance " + idroutedistance + " and numMapMarkers"+ numMapMarkers +":", button);

      if (button != null){
        console.log("BUG-864", "Adding onClick listener for idroutedistance " + idroutedistance);
        button.onclick = () => {
          this.props.toggleRouteVisible(idroutedistance);
          this.renderSharedRoutes(this.props.sharedRoutes, this.props.originSite, true);
        }
        console.log("BUG-864", "button after adding onClick:", button)
      } else {
        console.log("BUG-864", "Not adding onClick listener for idroutedistance " + idroutedistance);
      }
    });
  }

  clearActiveBubble = async () => {
    let {activeBubble} = this.state;
    if (activeBubble != null){
      activeBubble.close();
      activeBubble.removeEventListener('statechange', (evt) => this.bubbleStateChange(evt));
      this.setState({activeBubble: null});
    }
  }

  addMarkerBubble = (evt, ui, name) => {
    console.log("Calling addMarkerBubble");
    this.setState({clickingOnMarker: true});    

    this.clearActiveBubble();

    // event target is the marker itself, group is a parent event target
    // for all objects that it contains
    var bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
      content: this.bubbleContent(0, name)
    });

    bubble.addEventListener('statechange', (evt) => this.bubbleStateChange(evt))

    // show info bubble
    ui.addBubble(bubble);
    this.setState({activeBubble: bubble, clickingOnMarker: false})
  }

  addSetupBubble = (evt, ui, name, idsite, numMapMarkers) => {
    console.log("Calling addMarkerBubble");
    this.setState({clickingOnMarker: true});    

    this.clearActiveBubble();

    // event target is the marker itself, group is a parent event target
    // for all objects that it contains
    var bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
      content: this.bubbleContent(numMapMarkers, name, undefined, undefined, undefined, undefined, idsite)
    });

    bubble.addEventListener('statechange', (evt) => this.bubbleStateChange(evt))

    // show info bubble
    ui.addBubble(bubble);
    this.setState({activeBubble: bubble, clickingOnMarker: false}, () => {
      let button = document.getElementById('setup-button-'+idsite+'-'+numMapMarkers);

      if (button != null){
        button.onclick = () => {
          localStorage.setItem('site', JSON.stringify({idsite, name}));
          window.location.href = "/resource-check-setup";
        }
      } else {
      }
    })
  }  

  bubbleStateChange = (evt) => {
   this.setState({activeBubble: null})
  }

  pinBubbleStateChange = (evt) => {
    this.setState({tempMarkerBubble: null});
  }

  bubbleContent = (numMapMarkers, name, time, distance, visible, idroutedistance, idsite) => {
    console.log("Calling bubbleContent with params:", numMapMarkers, name, time, distance, visible, idroutedistance, idsite)
    let pinBubble = !name && !idsite;
    let routeBubble = (idroutedistance != undefined);
    let setupBubble = name && idsite && !idroutedistance;

    console.log("routeBubble val:", routeBubble);
    console.log("bubbleContent numMapMarkers:", numMapMarkers);

    let toReturn = '<div class="media-body">';

    if (!!name){
      toReturn += '<p class="strong text-dark mb-0 ml-1 '+ (routeBubble ? 'border-bottom' : null)+'">'+name+'</p>';
    }
     
    toReturn += (routeBubble ? 
      '<p class="mb-0 mx-1">'+
        '<span class="badge badge-pale badge-success" style="font-weight: 600">~'+time+'min</span>'+
        '<span class="strong text-dark fs-12 mr-2">'+Math.ceil(distance)+'mi</span>'+
        (!visible ? 
        `<span><button class="btn btn-round btn-xs btn-primary py-0" id="show-route-${idroutedistance}-${numMapMarkers}">Show Route</button></span>`
        : `<span><button class="btn btn-round btn-xs btn-outline-primary py-0" id="show-route-${idroutedistance}-${numMapMarkers}">Hide Route</button></span>`)
      +'</p>'+
    '</div>' : (pinBubble ? 
      `<span><button class="btn btn-round btn-xs btn-primary py-0" id="pinbubble-button-${numMapMarkers}">Name Site</button></span>`
    : (setupBubble ? 
       /*`<span class="strong text-dark fs-12 mr-2">${name}</span>`+*/
       `<span><button class="btn btn-round btn-xs btn-primary py-0" id="setup-button-${idsite}-${numMapMarkers}">Enter Site Needs</button></span>`
    :'</div>')));

    return toReturn;
  }


  render() {

    let {location} = this.state;
    let {username, refreshSites, backendURL, setLocked, app} = this.props

    return (<Fragment>
      <div
        style={{ width: '100%', height:'inherit', maxHeight: 'inherit', minHeight: 'inherit'}}
        ref={this.ref}
        className="sandbox-wrapper"
      />

      <Dialog
        openDialog={() => this.setState({showNewSiteModal: true})}
        onCloseDialog={() => this.setState({showNewSiteModal: false}, this.deleteTempMarker)}
        isOpen={this.state.showNewSiteModal}
        width={'sm'}
        buttonText={'Close'}
        >
        <div style={{width: '100%'}}>
          <NewSite
            location={location}
            username={username}
            onCloseDialog={() => this.setState({showNewSiteModal: false})}
            refreshSites={refreshSites}
            backendURL={backendURL}
            closeModal={async (newSite) => {
              /*if (setLocked){
                setLocked(true);
              }*/

              let selectedSite = {
                name: newSite.projectName,
                location_y: newSite.newSite.longitud,
                location_x: newSite.newSite.latitud,
                haulSiteId: newSite.haulSiteId,
                idsite: newSite.assuredSiteId,
                iddiscoverymode: (app == "HaulCheck" ? 0 : null),
                haulSetup: false,
                idprofile: 1,
                tickets: 0,
                tons_dumped: 0,
                projects: 0,
              }

              let {tempMarker, tempMarkerBubble} = this.state.tempMarker;
              this.deleteTempMarker();
             
              let newMarker = await this.addMarker(selectedSite, "pending")
              newMarker.dispatchEvent('tap');

              this.setState({
                showNewSiteModal: false, 
                selectedSite,

                showInfoWindow: true
              })
            }}
          />
        </div>
      </Dialog>
    </Fragment>)
  }
}
