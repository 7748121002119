import React, {useEffect, Fragment, useState}  from 'react';
import {Accordion, Card} from 'react-bootstrap'
import SiteMaterials from './SiteMaterials'
import JwtDecode from 'jwt-decode'
import {
	PublishSubscribe,
	HaulCheckService,
	userService
} from '../../../services'
import {addChildToArray, removeChildFromArray, updateObjectBasedOnKey, usePrevious, hcGetIconForSiteOrMatch} from '../../../utils'
import _ from 'lodash'
import 'mqtt'
import {setSidebarPositionData} from "./utils/positionSidebar"

export default function SiteDisplay({passed_site, listObj, activeTab, index, isActiveSite, setActiveSite, sortMode, switchSidePanel, groupsListMode, hasGroup, setSelectedMaterial, selectedMaterial, openSiteTeamModal, getSiteInfo, openSettingsModal, canEditSite, deleteMaterial, editSiteName, deleteSite, idpmgroup, addSelf, removeSelf, moveSelf, isBrowser, isMobile, hasExternalAccess}){

	let [site, updateSite] = useState(_.cloneDeep(passed_site));
	let [iddiscoverymode, setIdDiscoveryMode] = useState(passed_site.iddiscoverymode); //For some reason, usePrevious with this = page doesn't work
	let [client, setClient] = useState(null);
	let [children, setChildren] = useState(passed_site.children ? _.cloneDeep(passed_site.children) : [])
	const previousChildren = usePrevious(children);
	let [childrenLoading, setChildrenLoading] = useState((sortMode !== "material"));
	let [siteShouldBeHidden, setSiteShouldBeHidden] = useState(sortMode !== "material");
	let [managerName, setManagerName] = useState("");
	let [unreads, updateUnreads] = useState(_.cloneDeep(passed_site.unreadThreads));
	const userJWT = localStorage.getItem('token')
	const userDecoded = JwtDecode(userJWT)
	const username = userDecoded ? userDecoded['cognito:username'] : null
	const name = userDecoded ? userDecoded['name'] : null
	let origin = "SI-frontend/MySites/Sidebar/SiteDisplay/";

	let setSubscription = async () => {
		let topic = "SI/site-materials/";

		if (sortMode !== "material"){
			origin += site.idsite;
			topic += site.idsite;
		} else {
			origin +=  idpmgroup+"/"+site.name;
			topic += idpmgroup+"/"+site.name;
		}

		client = await PublishSubscribe.quickSubscribe(
				origin,
				topic,
				handleMessage
			)

		setClient(client);		
	}

	let closeSubscription = async () => {
		if (client !== null){
			setClient(PublishSubscribe.closeClient(client, origin));
		}
	}

	useEffect(() => {
		setSubscription();
		return closeSubscription
	}, [])


	useEffect(() => {
		let myAccordionId = "collapse-"+(sortMode == "material" ? index : site.idsite)

		console.log("isActiveSite effect triggered with isActiveSite value " + isActiveSite);
		let myAccordion = document.getElementById(myAccordionId);

		if (isActiveSite == false){

			if (myAccordion && myAccordion.classList && myAccordion.classList.contains("show")){
				myAccordion.classList.remove("show");
			}

			if (sortMode != "material"){
				setTimeout(()=> {
					setChildren([])
				}, 200)
			}
			
		} else if (sortMode != "material" && isActiveSite == true){
			fetchSiteInfo();
			let delay = 0;
			
			console.log("isActiveSite effect myAccordion value:", myAccordion);
			if (!myAccordion){
				delay = 200;
			}

			setTimeout(()=>{
				if (!myAccordion){
					myAccordion = document.getElementById(myAccordionId);
				}

				if (myAccordion && myAccordion.classList && !myAccordion.classList.contains("show")){
					console.log("isActiveSite ")
					myAccordion.classList.add("show");
				}
			}, delay)
		} else if (sortMode == "material" && isActiveSite == true){
			if (myAccordion && myAccordion.classList && !myAccordion.classList.contains("show")){
				myAccordion.classList.add("show");
			}
		}
		

	}, [isActiveSite])

	useEffect(() => {
		let shouldBeHidden = false;

		if (listObj == "bidsList" && site.iddiscoverymode == 2){
			shouldBeHidden = true;
		} else if (listObj == "postsList" && site.iddiscoverymode == 1){
			shouldBeHidden = true;
		} else if (listObj == "setupList" && site.iddiscoverymode !== null){
			shouldBeHidden = true;
		} else {
			shouldBeHidden = ((sortMode == "material" && children.length == 0) || site.archived == 1 || site.deleted == 1)
		}

		if (shouldBeHidden){
			removeSelf(site.idsite);
			if (sortMode !== "material"){
				closeSubscription();
			}
		}

		setSiteShouldBeHidden(shouldBeHidden);

	}, [site.archived, site.deleted, sortMode, listObj, site.iddiscoverymode, children.length]);

	useEffect(() => {
		if (site.iddiscoverymode != iddiscoverymode){
			console.log("iddiscoverymode effect triggered with site.iddiscoverymode value " + site.iddiscoverymode + " and old iddiscoverymode value " + iddiscoverymode);
			let siteToMove = _.cloneDeep(site);
			siteToMove.iddiscoverymode = site.iddiscoverymode;
			moveSelf(siteToMove);
			setIdDiscoveryMode(site.iddiscoverymode);
		}
	}, [site.iddiscoverymode])

	useEffect(() => {
		if (activeTab == "mySites"){
			if (site.manager !== null && site.manager !== name && site.manager !== username){
				removeSelf(site.idsite);
				closeSubscription();
			}
		} else if (activeTab == "groupSites"){
			getManagerInfo();
		}

	}, [site.manager])

	useEffect(() => {

		if (activeTab == "material"){
			console.log("Children change effect being called on activeTab material");
			console.log("previousChildren:", previousChildren);
			console.log("")
			if (previousChildren !== undefined && previousChildren.length !== undefined){
				if (previousChildren.length > 0 && children.length == 0){
					//removeSelf();
				} else if (previousChildren.length == 0 && children.length > 0){
					console.log("Material accordion adding self due to children increasing from 0");
					addSelf();
				}
			}
		}

	}, [children])


	let handleMessage = (message) => {
		message = JSON.parse(message);

		console.log("handleMessage message:", message);

		//Add new materials in my sites and group sites
		if (message.type == "new-material"){
			setChildren((previousState) => addChildToArray(previousState, message.material, "idsite_resource", (sortMode == "material" ? "name" : "idsite_resource")));
		} else if (message.type == "updated-site"){
			updateSite((previousState) => updateObjectBasedOnKey(previousState, message.data))
		}  else if (message.type == "new-messages"){
			updateUnreads((previousState) => addChildToArray(previousState, message.data, true));
		} else if (message.type == "read-message"){
			updateUnreads(previousState => removeChildFromArray(previousState, message.data));
		}
	}

	const goToSetup = () => {
	    localStorage.setItem('site', JSON.stringify({idsite: site.idsite, name: site.name}));
	    window.location.href = "/resource-check-setup";
	}

	const goToSiteMaterials = () => {
		localStorage.setItem('site', JSON.stringify({idsite: site.idsite, name: site.name}));
	    window.location.href = "/site-materials";
	}

	let id = (sortMode == "material" ? index : site.idsite)


	let fetchSiteInfo = async () => {

		console.log("Calling fetchSiteInfo");

		if (sortMode !== "material"){
			setChildrenLoading(true);
			let fetchedChildren = await HaulCheckService.getHCMaterialsForSidebarSite(site.idsite);
			console.log("fetchedChildren:", fetchedChildren);
			setChildren([]);
			setChildren(fetchedChildren);
			setChildrenLoading(false);
		} 

		getManagerInfo();
	}

	let getManagerInfo = async() => {
		if (sortMode == "material" || activeTab == "mySites") return;
		let {manager, username, oldManager, idsite} = site;
	    let managerUsername = manager || username;

	    if (oldManager !== undefined) return

	    if (managerUsername != undefined && oldManager == undefined){
	    	let userData = await userService.getUserInfo(managerUsername)
	    	console.log("fetchSiteInfo userData:", userData);
	    	if (userData.user){
	    		setManagerName(userData.user)
	    	}
	    }
	}

	if (siteShouldBeHidden) return null;

	else return(<Fragment>

		<div className="col-12 accordion accordion-arrow-right accordion-light" id={"accordion-"+id}>

			<div className="card shadow-4" style={{overflow: "visible"}}>

				<p class="card-title fs-16 strong p-0 border-bottom">
					<a class="collapsed m-0 py-2 px-3 bg-light" onClick={() => setActiveSite(isActiveSite ? null : id)} data-toggle="collapse" href={"#collapse-"+id}>
						{(sortMode != "material") ? <span class="marker float-left mr-2">
							<img src={hcGetIconForSiteOrMatch(site, "site")} />
						</span> : null}
						{site.name}
						{unreads && unreads.length > 0 && hasExternalAccess ?
							<span className="badge badge-dot badge-primary mx-3 mb-3"></span>
						: null}  
					</a>
				</p>

				<div id={"collapse-"+id} className={"collapse border-top" + (isMobile ? " px-4 pt-3 pb-2" : "")} data-parent="#sidebarDiv">
					<div className={isBrowser ? "px-4 pt-3 pb-2" : ""}>

						{(sortMode != "material") ? <Fragment>    

							<div className="clearfix border-bottom pb-4 mt-1">
	                            <div className="btn-group mb-0 float-left">
	                            	<span class="btn btn-xs btn-round btn-outline-primary dropdown-toggle px-2 py-0" data-toggle="dropdown"><i class="fa fa-cog fs-12 pr-1" aria-hidden="true"></i>Site</span>
	                                {activeTab == "groupSites" ? <span class="lh-1 ml-2 my-1 small text-light float-left">Primary: {managerName}</span> : null}
										<div className="dropdown-menu border">
										{listObj == "setupList" ? <a className="dropdown-item text-dark" href="#" onClick={goToSetup}>Enter Site Needs</a> : null}
										{canEditSite && (listObj != "setupList") ? <a className="dropdown-item text-dark" data-toggle="modal" data-target="#add-material" href="#">Add a Material</a> : null}
										{canEditSite ? <a className="dropdown-item text-dark" href="#" onClick={() => {openSiteTeamModal(id)}}>{(site.siteTeamUsers && site.siteTeamUsers.length > 1) ? "Edit" : "Enter"} Site Team</a> : null}
										{!canEditSite ? <a className="dropdown-item text-dark" href="#" onClick={() => {openSiteTeamModal(id)}}>View Site Team</a> : null}
										{canEditSite && (listObj != "setupList") ? <a className="dropdown-item text-dark" href="#" onClick={goToSiteMaterials}>Site Settings</a> : null}
										<div class="dropdown-divider"></div>
										{canEditSite && (listObj != "setupList") ? <>
											{site.iddiscoverymode == 1 ? 
												<a className="dropdown-item text-dark" href="#" onClick={() => {openSettingsModal(id, "rolloverbid", site)}}>Update Site Phase</a>
											: null}

	                                     	{/*<a className="dropdown-item text-dark" href="#" onClick={() => {openSettingsModal(id, "radius", site)}}>Update Match Radius</a>*/}
	                                    	{/*<a className="dropdown-item text-dark" href="#" onClick={() => {openSettingsModal(id, "archiveSite", site)}}>Archive Site</a>*/}
	                                    </> : null}
	                                    {/*(listObj == "setupList") ? <a className="dropdown-item text-dark" href="#" onClick={() => {editSiteName(site)}}>Edit Site Name</a> : null*/}
	                                    {/*<a className="dropdown-item text-dark" href="#" onClick={() => {deleteSite(site, (children && children.length > 0))}}>Delete Site</a>*/}
										
									</div>
								</div>
							</div>

						</Fragment> : null}

						<div className="card-body pl-1 pr-1 pt-2 pb-0">
								
							{ !_.isEmpty(children) ? 
								children.map((material, key) => {
									return <SiteMaterials
										passedMaterial={material}
										switchSidePanel={switchSidePanel}
										key={material.idsite_resource}
										hasGroup={hasGroup}
										sortMode={sortMode}
										isHighlighted={material.idsite_resource == selectedMaterial}
										setSelected={setSelectedMaterial}
										openSettingsModal={openSettingsModal}
										canEditSite={canEditSite}
										deleteMaterial={deleteMaterial}
										removeSelf={(idsite_resource) => setChildren(previousState => removeChildFromArray(previousState, idsite_resource, "idsite_resource"))}
										isBrowser={isBrowser}
										isMobile={isMobile}
										hasExternalAccess={hasExternalAccess}
									 />
								}
							) :
							 childrenLoading ? "Loading..." : (listObj != "setupList") ?
									<small><em>No materials for this site</em></small> 
								:
								<a className="btn btn-xs btn-round btn-outline-primary py-0 mr-3" href="#" onClick={goToSetup}>Enter Site Needs</a>
							 }

						</div>
					</div> 
				</div>
			</div>

		</div>

          
	</Fragment>)

}