import React, { Component, Fragment } from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import SuccessPopup from '../common/Popups';
import { url } from '../../constants'

import {
  isMobile,
  isBrowser
} from 'react-device-detect'

import {hcGetIconForSiteOrMatch} from "../../utils"

import moment from "moment";
import DatePicker from 'react-datepicker' 
import jwtDecode from 'jwt-decode'

import {
  ApiService,
  SharesDetailsService
} from '../../services'

import { Popover, OverlayTrigger } from 'react-bootstrap';


export default class Invite extends Component {
    constructor(props) {
        super(props);

        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        let senderName = userDecoded['name'];
        let inviteeEmail = userDecoded['email'];
        let senderCompany = userDecoded['custom:company'];

        let landingPageUrl = url.frontend;
        let marketingPageUrl = "https://spare-iron.earthideal.com/"

        let emailsList = [
            {email: "", status: "", name: "", numPreviousInvites: 0},
            {email: "", status: "", name: "", numPreviousInvites: 0},
        ]

        if (this.props.type == "standard"){
            emailsList.push({email: "", status: "", name: "", numPreviousInvites: 0});
        }

        this.state = {
            emailsList,
            materials: [],
            idsite_resource: 0,
            activeMaterial: {resource: "[Material]", type: "[Export/Import]"},
            idsite: 0,
            inviteeEmail,
            senderName,
            senderCompany,
            hcUrl: landingPageUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0],
            hcUrlFull: landingPageUrl,
            hcMarketingUrl: marketingPageUrl.replace(/^(?:https?:\/\/)?/i, "").split('/')[0],
            hcMarketingUrlFull: marketingPageUrl,
            startDate: null,
            startDateString: "[Start Date]",
            endDate: null,
            endDateString: " to [End Date]",
            dateFormat: "MM/DD/YY",
            successPopupInvitesSent: false,
            type: props.type, //Only included for the sake of sending to the endpoint more easily
            maxNumInvites: 5, //Included so that there's one centralized spot in the code for this number
            invitesSent: false 
        }
    }

    componentWillMount = async () => {
        let {inviteeEmail} = this.state;
        let {type} = this.props;
        let idsite_resource = false;
        if (type == "blast"){

            let key = JSON.parse(localStorage.getItem("postInfo")).material;
            console.log("materials Key:", key);
            let idsite_resource = null;
            if (!key){
                window.location.href = url.frontend + "/my-sites";
            } else {
                idsite_resource = key.idsite_resource;
            }

            let materials = await ApiService.getEveryExternalPost(inviteeEmail);
            
            if (key){
                materials.unshift(key);
            }

            console.log("materials:", materials);

            this.setState({materials}, () => {
                console.log("idsite_resource:", idsite_resource);
                if (idsite_resource){
                    this.changeMaterial(idsite_resource);
                }
            });
        }
    }

    renderHeading = () => {
        let {name, sitename, resource, type} = this.state.activeMaterial

        let typeLabel = (type == "Import" ? "Shortage" : "Surplus")

        let icon = hcGetIconForSiteOrMatch(this.state.activeMaterial, "myMaterial");

        if (isBrowser){
            return <>
                <div class="py-2 pr-2">
                    <div class="clearfix">
                        <figure class="marker float-left mr-2">
                            <img src={icon} />
                        </figure>
                        <p class="fw-600">{name || sitename}</p>
                    </div>
                </div>
              <p className="lead-4">{resource} {typeLabel}</p>

            </>
        } else {
            return <> 
                <div class="clearfix">
                    <figure class="marker float-left mr-2">
                        <img src={icon} />
                    </figure>
                    <p class="fw-600">{name || sitename}</p>
                </div>
                <p className="lead-4">{resource} {typeLabel}</p>
            </>
        }
                        
    }


    updateEmailVal = (index, value, key) => {
       let {emailsList} = this.state;
       emailsList[index][key] = value;

       if (key == "email"){
        emailsList[index]["status"] = "";
        emailsList[index]["numPreviousInvites"] = 0;
       }

       this.setState({emailsList});
    }

    addEmailRow = () => {
        let {emailsList} = this.state;
        emailsList.push({email: "", status: "", name: ""});
        this.setState({emailsList});
    }

    removeEmailRow = (i) => {
        let {emailsList} = this.state;
        emailsList.splice(i, 1);
        this.setState({emailsList});
    }

    setDate = (type, date) => {
        let {dateFormat} = this.state;

        this.setState({
            [type+"Date"]: date,
            [type+"DateString"]: moment(date).format(dateFormat)
        })

    }

    handleSendEmails = async () => {

        console.log("Sending emails to " + this.state.emailsList);

        let emailsSent = await ApiService.sendInviteEmails(this.state);
        console.log(emailsSent);
        if (this.props.type == "blast"){
            //localStorage.setItem("matchesPanelKey", JSON.stringify(this.state.activeMaterial));

            let numReferrals = 0;

            for (var key = 0; key < this.state.emailsList.length; key++){
                let inSystem = (this.state.emailsList[key].status === "true_with_materials");
                let tooManyInvites = (this.state.emailsList[key].numPreviousInvites >= this.state.maxNumInvites);
                let checked = (this.state.emailsList[key].status !== "");

                let invalid = (inSystem || tooManyInvites)

                if (!invalid && checked) numReferrals++;
            }

            if (numReferrals >= 2){
                let postInfo = JSON.parse(localStorage.getItem('postInfo'));
                postInfo.hasReferralCredits = true;
                localStorage.setItem('postInfo', JSON.stringify(postInfo));
            }

            //window.location.href='/checkout'

        }
        this.setState({successPopupInvitesSent: true, invitesSent: true});
    }

    handleVerifyEmail = async () => {
        let {emailsList} = this.state;
        let rawEmailsList = emailsList.map(({email}) => {return email});
        let {usersExist, numPreviousInvites} = await ApiService.checkEmailsExist(rawEmailsList, true);

        for (var i = 0; i < usersExist.length; i++){
            this.updateEmailVal(i, usersExist[i], "status");
            this.updateEmailVal(i, numPreviousInvites[i], "numPreviousInvites")
        }
    }

    getStartEndDateForSiteResource = async (idsite_resource) => {
        console.log("Calling getStartEndDateForSiteResource with value " + idsite_resource);
        if (idsite_resource == 0) return;
        let {start_date, end_date} = await SharesDetailsService.getStartEndDateForSiteResource(idsite_resource);
        if (start_date != null) this.setDate("start", new Date(start_date));
        if (end_date != null) this.setDate("end", new Date(end_date));
    }

    submitIsDisabled = () => {
        let {emailsList, startDateString, endDateString, idsite_resource, invitesSent, maxNumInvites} = this.state;
        let {type} = this.props

        if (invitesSent) return true;
        else {

            //Start by checking for materials and dates
            if (type == "blast" && (startDateString === "[Start Date]" || endDateString === "[End Date]" || idsite_resource == 0)){
                console.log("submitIsDisabled - returning true because of dates or resource");
                return true;
            }

            console.log("submitIsDisabled calling the line after the initial check");

            let emailsValid = true;
            let blankCount = 0;
            for (var i = 0; i < emailsList.length; i++){
                let {email, status, numPreviousInvites, name} = emailsList[i];
                if (email !== ""){
                    console.log("numPreviousInvites for email " + email + ":", numPreviousInvites);
                    console.log("numPreviousInvites >= "+maxNumInvites+"?", numPreviousInvites >= maxNumInvites)
                    if ((status === "true_with_materials" || status === "") || name == "" || numPreviousInvites >= maxNumInvites){
                        console.log("emailsValid set to false at index " + i, emailsList[i]);
                        emailsValid = false;
                    }
                } else {
                    blankCount++;
                }
            }

            console.log("submitIsDisabled blankCount: " + blankCount, "emails list length: " + emailsList.length, "blankCount == emailsList.length: " + (blankCount == emailsList.length))
             console.log("submitIsDisabled - emailsValid is " + emailsValid);
            if (blankCount == emailsList.length) return true;
            else return (!emailsValid);
        }
    }

    changeMaterial = (idsite_resource) => {
        console.log("changeMaterial called for " + idsite_resource);
        this.setState({idsite_resource});
        let {materials, dateFormat} = this.state;

        for (var i = 0; i < materials.length; i++){
            if (materials[i].idsite_resource == idsite_resource){

                let material = materials[i];
                let end_date = material.available_end_date;
                if (!material.available_end_date){
                    end_date = material.available_date
                }

                let availability = moment(material.available_date).add('days', 1).format("YYYY-MM-DD");
                let availabilityend = moment(end_date).add('days', 1).endOf('month').format("YYYY-MM-DD");

                let startDate = new Date();
                let endDate = new Date();

                if (availability){
                    startDate = new Date(availability);
                    startDate.setDate(startDate.getDate() + 1);

                    if (availabilityend){
                        endDate = new Date(availabilityend);
                        endDate.setDate(endDate.getDate() + 1);
                    } else if (this.state.availability){
                        endDate = new Date(availability);
                        endDate.setDate(endDate.getFullYear(), endDate.getMonth()+2, 0);
                    }
                }

                console.log("changeMaterial material found:", materials[i]);
                materials[i].type = materials[i].type.split('/')[1];
                materials[i].groupsListMode = false;
                materials[i].idsiteresource = materials[i].idsite_resource;
                this.setState({ 
                    activeMaterial: materials[i],
                    startDate,
                    endDate,
                    startDateString: moment(startDate).format(dateFormat),
                    endDateString: (materials[i].perennialEndDate == 1 ? " onward" : " to " + moment(endDate).format(dateFormat))
                })
            }
        }
    }

    goBackToPrevPage = () => {
        if (document.referrer.indexOf("earthideal") == -1 && document.referrer.indexOf("haulcheck") == -1){
            window.location.href = "/my-sites";
        } else {
            this.props.history.goBack();
        }
    }

    render = () => {

        let {type} = this.props;
        let {emailsList, activeMaterial, senderName, senderCompany, hcUrl, hcUrlFull, startDateString, endDateString} = this.state;

        const blastPopover = (
          <Popover>
            <Popover.Body>
              A promotional credit will be applied to your next monthly invoice. Limited to the number of posts on the invoice.
            </Popover.Body>
          </Popover>
        )

        /*const standardPopover = (
          <Popover>
            <Popover.Header as="h3">Network Referrals</Popover.Header>
            <Popover.Body>
              Did you know that you can get free posts with our Post Referral option? The next time you post externally, look for the checkbox in the payment flow.
            </Popover.Body>
          </Popover>
        )*/

        return(<Fragment>
            <Navbar showSandbox={true} />

            <header class="header bg-gray pt-6 pb-4">
                <div class="container">
                    <h1 class="display-4">{type == "blast" ? "Post & Tell Others" : "Grow the Network"}</h1>
                    <p class="lead-2 mt-6">
                        {type == "blast" ? 
                            "Tell others about your Post on Haul Check and get it for free."
                        :
                            "Tell others about Haul Check to build up the network!"
                        }
                    </p>

                    {type == "blast" ? <>
                         <OverlayTrigger trigger="hover" placement="left" overlay={blastPopover}>
                            <a tabindex="0" class="cursor float-right" >
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                            </a>
                        </OverlayTrigger>
                        <p class="small">Get this post for free when you tell two people about your post on Haul Check.</p>
                        
                           
                    </>:
                        <p class="small">Did you know that you can get free posts with our Post Referral option? The next time you post externally, look for the option in the payment flow.</p>
                    }


                </div>
            </header>

            <main className="main-content">
               
                <div className="section pt-4">
                    <div className="container">
                        <div className="row">


                            <div className="col-lg-10 mx-auto mb-6"> 

                                <form className="">

                                    {type == "blast" ?
                                        /*<div className="row mt-4 mb-2 ml-0 bg-pale-primary">
                                            <div className="col-lg-10 mr-auto pl-4 m-2 pb-6">
                                                <p className="lead-1 mb-0 pt-2 pl-0 pr-2"><b>Select your site imbalance:</b></p>
                                                <div className="form-group row gap-y mt-0 input-line fs-13 px-2">

                                                    <div className="col-5 col-md-6 p-2 p-md-2">
                                                        <select className="form-control"
                                                            value={this.state.idsite_resource} 
                                                            onChange={e => {
                                                                this.changeMaterial(~~e.target.value);
                                                                this.getStartEndDateForSiteResource(~~e.target.value);
                                                            }}>
                                                                {this.renderMaterialsOptions()} 
                                                        </select>  
                                                    </div>
                                                    <div className="col-5 col-md-3 p-2 p-md-2">
                                                        <DatePicker
                                                            className={"form-control"}
                                                            dateFormat={"MM/dd/yy"}
                                                            selected = {this.state.startDate}
                                                            onChange={ date => this.setDate("start", date) }
                                                            placeholderText="Start Date"
                                                        />
                                                    </div>
                                                    <div className="col-5 col-md-3 p-2 p-md-2">
                                                        <DatePicker
                                                            className={"form-control"}
                                                            dateFormat={"MM/dd/yy"}
                                                            selected = {this.state.endDate}
                                                            onChange={ date => this.setDate("end", date) }
                                                            placeholderText="End Date"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>*/
                                        this.renderHeading()

                                    : null}

                                    <div className="row mt-4 mb-2 ml-0">
                                        <div className="mr-auto pl-4 m-2 pb-6">
                                            <p className="lead-1 mb-0 pt-2 pl-0 pr-2"><b>{type == "blast" ? "Who would you like to tell?" : "Who would you like to tell?"}</b></p>

                                            <div className="col-12 col-lg-11 mt-6 mx-lg-auto">
                                                <div className="form-group row gap-y">
                                                    {emailsList.map(this.renderEmailInputs)}
                                                    {emailsList.length < 10 ? <button type="button" className="btn btn-outline-secondary mb-5 mb-lg-0 ml-lg-6 px-4" onClick={this.addEmailRow}><i className="fa fa-plus fw-400"></i></button> : null}
                                                </div>
                                            </div>
                                            
                                            <button type="button" className="btn btn-sm btn-outline-primary mt-6 ml-6" onClick={this.handleVerifyEmail}>Check for Existing Accounts</button>
                                        </div>
                                    </div>

                                </form> 

                                <em class="small">An automated message will be sent to these recipients. A message preview is below.</em>

                                <hr className="mt-6 mb-4" />                               

                            </div>

                            <div className="col-lg-10 mx-auto mb-6 flexbox pt-4 pb-8">
                                <a className="btn btn-secondary" onClick={this.goBackToPrevPage}><i className="fa fa-angle-left ml-1"></i> CANCEL</a>
                                <button className="btn btn-primary" type="button" href="#" onClick={this.handleSendEmails} disabled={this.submitIsDisabled()}>SEND REfERRALS</button>
                            </div>

                            <div className="row mt-5">
                                <div className="col-11 border-primary mx-auto mb-7 flexbox pb-4 pl-5">
                                    <div className="mt-4 fw-400 p-4 pr-6">
                                        {type == "blast" ?
                                            this.renderBlastEmail()
                                        :
                                            this.renderStandardEmail()
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>

             <SuccessPopup
                popupId="inviteSentPopup"
                title="Success!"
                message="Referrals sent"
                trigger={this.state.successPopupInvitesSent}
                callback={() =>  {window.location.href = "/checkout"}}
                useButton = {true}
                disallowClose = {true}
                />

            <Footer />
        </Fragment>)
    }

    renderBlastEmail = () => {
        let {emailsList, activeMaterial, senderName, senderCompany, hcMarketingUrl, hcMarketingUrlFull, hcUrl, hcUrlFull, startDateString, endDateString} = this.state;
        return <>
            <p className="lead mb-2 border-bottom mb-6">Referral Email Preview</p>
            <p className="small">Here's the email that we’ll send on your behalf:</p>
            <p className="strong mt-2 mb-6">Looking To {activeMaterial.type} {activeMaterial.resource}</p>

            <p>Hi {emailsList[0].name || "[First Name]"},</p>
            <p>You are receiving this email from Haul Check on my behalf.</p>
            <p>I have a site looking to <strong>{activeMaterial.type} {activeMaterial.resource} </strong> from <strong>{startDateString}{endDateString}</strong>.</p>
            <p>Thanks,</p>

            <p>{senderName}</p>
            <p>{senderCompany} <br /><br /></p>

            <p>Spare iron by Earth Ideal ©2023</p>

        </>
    }

    renderStandardEmail = () => {
        let {emailsList, activeMaterial, senderName, senderCompany, hcMarketingUrl, hcMarketingUrlFull, hcUrl, hcUrlFull, startDateString, endDateString} = this.state;
        return <>
            <p className="lead mb-2 border-bottom mb-6">Referral Email Preview</p>
            <p className="small">Here's the email that we’ll send on your behalf:</p>
            <p className="strong mt-2 mb-6">{senderName} thinks you should try Spare Iron</p>

            <p>Hello {emailsList[0].name || "[First Name]"}!</p>

            <p>I’ve been using the Spare Iron network to find I’ve been using the Spare Iron network to find and rent equipment in a whole new way.</p>

            <p>Go to <a href={hcMarketingUrlFull}>{hcMarketingUrl}</a> to learn more, or go to <a href={hcUrlFull}>{hcUrl}</a> to register and log in<br /><br /></p>


            <p>Thanks,</p>

            <p>{senderName}</p>
            <p>{senderCompany} <br /><br /></p>

            <p>Spare Iron by Earth Ideal ©2021</p>

        </>
    }

    renderEmailInputs = (email, key) => {

        let inSystem = (this.state.emailsList[key].status === "true_with_materials");
        let tooManyInvites = (this.state.emailsList[key].numPreviousInvites >= this.state.maxNumInvites);
        let checked = (this.state.emailsList[key].status !== "");

        let invalid = (inSystem || tooManyInvites)

        return(<Fragment key={key}>

            {!invalid && checked ? <i className="ti-check text-success mt-2 mr-2 float-left"></i> :null}
            {invalid && checked ? <i className="ti-close text-danger cursor mt-2 mr-2 float-left" onClick={() => this.removeEmailRow(key)}></i> :null}
            <div className="col-12 col-lg-4 pt-2 px-0 pt-lg-0 pl-lg-3">

                <div className="">
                    <input className={"form-control " + (invalid ? " border-danger" : "")} 
                    type="text"  placeholder="First Name"
                    value={this.state.emailsList[key].name || ""} 
                    onChange={(e) => {
                        this.updateEmailVal(key, e.target.value, "name")
                    }} />

                </div>
            </div>
            <div className="col-lg-7 p-0 pl-lg-4 mr-lg-5">
                <div className="">

                    <input className={"form-control " + (invalid ? " border-danger" : "")} 
                    type="email"  placeholder="Email Address"
                    value={this.state.emailsList[key].email || ""} 
                    onChange={(e) => {
                        this.updateEmailVal(key, e.target.value, "email")
                    }} />

                </div>
            </div>

            {/*inSystem? <small className="text-danger">Our records show that this user is already using HaulCheck</small> : null*/}

        </Fragment>)
    }

    renderMaterialsOptions = () => {
        let {materials} = this.state;

        return(<Fragment>
            <option value={null}>
                {materials.length > 0 ? 
                    "No Material Selected"
                :
                    "There are no posted materials..."
                }</option>

            {materials.map((material, i) => {
                if (!material) { return null }
                let {idsite_resource, resource, type, name} = material;
                return(
                    <option value={idsite_resource} 
                        key={i} >
                            {name} - {type ? type.split('/')[1] : ""} {resource}
                    </option>
                )
            })}

        </Fragment>)
    }

}
