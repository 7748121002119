import React, { Component, Fragment } from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import MatchesSidebar from './MatchesSidebar'
import MatchesInbox from './MatchesInbox'
import { url, earthidealAPIURL } from '../../constants'
import jwtDecode from "jwt-decode";
import {
  //ApiService,
  HaulCheckService,
  PMGroupsService,
  //MatchesService,
  //ConversationsService
} from '../../services'
class Matches extends Component {

  constructor(props){
    super(props);
    let site = localStorage.getItem('site');
    let idsite = site ? JSON.parse(site).idsite : null;
    let idsiteresource = site ? JSON.parse(site).idsite_resource : null;
    let namesite = site ? JSON.parse(site).name : null;
    const userJWT = localStorage.getItem('token');
    const userDecoded = jwtDecode(userJWT);
    const user = userDecoded ? userDecoded['cognito:username'] : null;
    const email = userDecoded ? userDecoded['email'] : null;

    this.state = {
      results: [],
      title:[],
      resourcesList:[],
      inputHaul:'',
      user,
      email,
      iddiscoverymode: 1,
      idsite,
      idsiteresource,
      namesite,
      allSiteResourcesList:[],
      pmGroupName: "My Matches",
      idpmgroup: null,
    }
  }

  async componentDidMount() {
    let {email, user, idsite, idsiteresource} = this.state;


    let allSiteResourcesList = await HaulCheckService.getAllResources(user);
    allSiteResourcesList = allSiteResourcesList.resourcesList;
 
    console.log("calling getPmGroupByEmail from Matches/componentDidMount");
    let pmGroup = await PMGroupsService.getPmGroupByEmail(this.state.email);

   
    console.log("allSiteResourcesList", allSiteResourcesList)
    this.setState({
      allSiteResourcesList,
      pmGroupName: (pmGroup ? pmGroup.name : "My Imabalances")
    });
    await this.switchSiteResource(idsite, idsiteresource)
  }

  switchSiteResource = async (idsite, idsiteresource) => {
    this.setState({idsite, idsiteresource});

    if(idsiteresource){
      fetch(`${url.backend}/matches/getmatchsbyidsiteresource/${idsiteresource}`)
          .then(res =>res.json())
          .then(results => {
            const  { matchesList } = results
            this.setState({ results: matchesList })
          })
    fetch(`${url.backend}/matches/getTitleMatchsByIdSiteResource/${idsiteresource}`)
          .then(res =>res.json())
          .then(results => {
            const  { matchTitle } = results
            this.setState({ title: matchTitle[0] })
          })
    }else{
      fetch(`${earthidealAPIURL}/matches/getmatches/${idsite}/${this.state.email}`)
          .then(res =>res.json())
          .then(results => {
            const  { matchesList } = results
            this.setState({ results: matchesList })
          })
    }
  } 

  render() {
    return (
        <Fragment>
          <Navbar showSandbox={true}/>
          <nav className="nav navbar-dark bg-gray border-bottom">
            <a className="nav-link ml-lg-11 small p-1" href="haul-checks"><i className="fa fa-angle-double-left mr-2" aria-hidden="true"></i>All Searches & Posts</a>
          </nav>
          <main className="main-content">
            <header className="section-header mt-5 mb-1">
              <h2><strong>Haul Check - {this.state.pmGroupName}</strong></h2>
            </header>

            <div className="section mb-6 py-2">
              <div className="container">

                {/* Tab Content */}

                <div class="col-11 mx-auto mb-6 p-0">  
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link" href="/company-imbalances">Company Imbalances</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" href="#">Matches Inbox</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Archive</a>
                    </li>
                    <li class="nav-item ml-auto">
                      <div class="dropdown mt-3 mr-4">
                        <span class="dropdown-toggle no-caret" data-toggle="dropdown"><i class="ti ti-settings text-lighter" aria-hidden="true"></i></span>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item cursor" data-toggle="modal" data-target="#">Option</a>
                          <a class="dropdown-item cursor" href="#" style={{display: 'block'}}>Option</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                {/* MATCH-RESOURCE HEADER */}
                <div className="row col-12 mx-auto">

                    <MatchesSidebar 
                      siteResourcesList={this.state.allSiteResourcesList} 
                      switchSiteResource={this.switchSiteResource}
                      idsite={this.state.idsite}
                      idsiteresource={this.state.idsiteresource}
                     />
                    <div class="col-9 ml-auto">
                      <div class="tab-content">


                      <div class="tab-pane fade show active" id="tab-1">
                        <MatchesInbox title={this.state.title} matches={this.state.results} idsite={this.state.idsite}  />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </Fragment>
    )
  }
}

export default Matches
