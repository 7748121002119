import React, {Component, Fragment}  from 'react';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import {url, earthidealAPIURL} from '../../constants';
import ajax from 'superagent';

export default class Analytics extends Component {

	constructor(props){
		super(props);
		const jwtToken = localStorage.getItem('token');
		let defaultAnalyticsObj = [
			{
				rows: [
					"Loading..."
				]
			}
		];

		this.state = {
			analytics: JSON.parse(JSON.stringify(defaultAnalyticsObj)),
			defaultAnalyticsObj,
			includeCWP: 0,
			jwtToken,
			gettingADUsers: false,
			gettingHCUsers: false,
			gettingCHUsers: false,
		}
	}

	componentWillMount = () => {
		this.getAnalytics();
	}

	getAnalytics = () => {
		let {jwtToken, includeCWP} = this.state;

		this.setState({analytics: JSON.parse(JSON.stringify(this.state.defaultAnalyticsObj))})

		ajax.get(`${earthidealAPIURL}/analytics/getHCAnalytics/${jwtToken}/${includeCWP}/${this.props.mode}`)
            .end((error, response) => {
                if (!error && response) {
                    let {results} = response.body;
                    this.setState({analytics: results});
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
	}

	toggleCWP = () => {
		let {includeCWP} = this.state;

		includeCWP = (includeCWP == 1 ? 0 : 1);
		this.setState({includeCWP}, this.getAnalytics);
	}

	getUsersCsv = (app) => {
		let {jwtToken, includeCWP} = this.state;

		this.setState({['getting'+app+'Users']: true});


		ajax.get(`${earthidealAPIURL}/analytics/getAllUserEmails/${jwtToken}/${app}`)
            .end((error, response) => {
                if (!error && response) {
                    let {results} = response.body;

                    this.setState({['getting'+app+'Users']: false}, () => {


	                   	let csvContent = "data:text/csv;charset=utf-8," 
	    				+ results;

	    				console.log("csvContent:", csvContent);

                    	let encodedUri = encodeURI(csvContent);
						let link = document.createElement("a");
						link.setAttribute("href", encodedUri);
						link.setAttribute("download", app+"-users-"+(new Date()).toISOString().substr(0,10)+".csv");
						document.body.appendChild(link); 
						link.click();

                    });
                } else {
                    console.log('There was an error fetching', error);
                }
            }
        );
	}

	render = () => {
		let {analytics, includeCWP, gettingHCUsers, gettingADUsers, gettingCHUsers} = this.state;

		return (<Fragment>

			{(this.props.mode == "tech" && analytics.length > 1) ? <>

				<h5 className="ml-2">Lists of users per app</h5>

				<button className="ml-2 btn btn-primary" onClick={() => this.getUsersCsv('AD')} disabled={gettingADUsers || gettingHCUsers || gettingCHUsers}>
					{gettingADUsers ? <img src="../../img/app/photoswipe/preloader.gif" /> : null}
					AD Users
				</button>
				<button className="btn btn-primary mx-5"  onClick={() => this.getUsersCsv('HC')} disabled={gettingADUsers || gettingHCUsers || gettingCHUsers}>
					{gettingHCUsers ? <img src="../../img/app/photoswipe/preloader.gif" /> : null}
					HC Users
				</button>
				<button className="btn btn-primary"  onClick={() => this.getUsersCsv('CH')} disabled={gettingADUsers || gettingHCUsers || gettingCHUsers}>
					{gettingCHUsers ? <img src="../../img/app/photoswipe/preloader.gif" /> : null}
					CH Users
				</button>

				<hr className="mx-5" />

			</> : null}

			{analytics.length > 1 ? <button className="btn btn-outline-success m-5" onClick={this.toggleCWP}>Toggle CWP (currently {includeCWP == 1 ? "included" : "excluded"})</button> : null}
			{analytics.length > 0 && analytics.map != undefined ? analytics.map(({title, rows}, index) => {
				return<>

					 <table className="table table-bordered table-responsive ml-5" key={index}>
						{title !== "" && title != undefined ? <tr><th colspan="25">{title}</th></tr> : null}
						{rows.length && rows.length > 0 ? 
							rows.map((cols, key) => {
								return <tr key={key}>
									{cols.length && cols != undefined && cols.map != undefined ? 
										cols.map((colData, key) => {
											return this.renderDataCell(colData, key)
										})
									: this.renderDataCell(cols) }
								</tr>
							})
						: null}
					</table>
			</>}) : null}
		</Fragment>);
	}

	renderDataCell = (data, key) => {
		console.log("renderDataCell data:", data);
		return (data !== "" && data != undefined ? 
			<td key={key}>
				{data.length && data.length > 0  && data.map != undefined ?
					<ul>
						{data.map((item, key2) => {
							return <li key={key2}>{item}</li>
						})}
					</ul>
				: data}
			</td> 
		: null)
	}
}