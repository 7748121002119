import mqtt from 'mqtt'
let options = {
  username: "HCActiveMQ",
  password: process.env.REACT_APP_AMQ_PASSWORD, //Password and endpoint in .env file for security reasons
  //clientId: 'mqttLambda_HC', //Not included as it causes connection to fail for some reason
  protocolId: 'MQTT',
  protocolVersion: 4,
  // protocolId: 'MQIsdp',
  //protocolVersion: 3,
  debug: false,
  keepalive: 60,
  clean: true,
  reconnectPeriod: 1000,
  queueQoSZero: true,
}

let mqEndpoint = process.env.REACT_APP_AMQ_ENDPOINT;

async function quickSubscribe(originName = "[caller unknown]", topic = false, handleMessageCallback = false, offlineCallback = false){

  let client = mqtt.connect(mqEndpoint, options);

  if (topic == false) return;

  client.on('connect', () => {

    client.subscribe(topic, (err)=>{
      if (err){
        console.log("AMQ quickSubscribe error from origin "+originName+":", err);
      } else {
        console.log("AMQ quickSubscribe success from origin " + originName + " with topic " + topic);
      }
    });
  })

  client.on('offline', async(packet) => {
    console.log("AMQ", "quickSubscribe offline from origin " + originName);
    // According to documentation in this case the client is the one who has gone offline,
    // Ask to reload! or try to reconnect
    client.reconnect();
    //client.end();
    if (offlineCallback) offlineCallback();
  });

  client.on('message', async (topic, message) => {
    //console.log("AMQ", "quickSubscribe recieved raw message", message, " from topic " + topic)
    //let message = new TextDecoder("utf-8").decode(message_raw)

    message = await parseUint8Array(message);

    console.log("AMQ", "quickSubscribe recieved message ",message," from topic " + topic);
    /*console.log("AMQ", "message.payload:", message.payload);
    console.log("AQM")
    console.log("AMQ", "message instanceOf uint8Array:", message instanceof Uint8Array);*/
    if (handleMessageCallback) handleMessageCallback(message);
  })

  client.on('error', (err) => {
    console.log("AMQ quickSubscribe error from origin "+originName+":", err);
    if (err==='ECONNRESET') {
      client.reconnect();
    }
  })

  client.on('close', () => {
    console.log("AMQ quickSubscribe connection closed from " + originName);
  })

  return client;
}

async function parseUint8Array (message){
  if (message instanceof Uint8Array){
    //console.log("AMQ", "Message being parsed from Uint8Array:", message);
    var plainMessage = "";
    for (var i = 0; i < message.length; i++) {
      plainMessage += String.fromCharCode(parseInt(message[i]));
    }
    //console.log("AMQ","plainMessage:", plainMessage);

    return plainMessage;
  } else {
    return message;
  }
}

async function closeClient(client = null, originName = "[caller unknown]"){
  console.log("AMQ calling closeClient from " + originName);
  if (client !== null && client.end !== undefined && typeof client.end === 'function') client.end();
  return client;
}

export const PublishSubscribe = {
	quickSubscribe, closeClient
}
