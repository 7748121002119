import React, {Fragment, Component} from 'react'
import './index.css'
import DatePicker, { registerLocale } from 'react-datepicker'
import enUS from 'date-fns/locale/en-US'
import moment from 'moment'
import SharedDetailsDisplay from '../common/SharedDetailsDisplay'
import {HaulCheckService, SharesDetailsService} from '../../services'
import SuccessPopup from '../common/Popups'
import jwtDecode from 'jwt-decode';

registerLocale("en-US", enUS);

export default class ModalShareDetails extends Component{

    constructor(props) {
        super(props)

        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        const user = userDecoded ? userDecoded['cognito:username'] : null;
        const name = userDecoded ? userDecoded['name'] : null;
        const mail = userDecoded ? userDecoded['email'] : null;
        const corporation = userDecoded ? userDecoded['custom:company'] : null;
        const phonenumber = userDecoded ? userDecoded['custom:phone'] : null;

        this.state = {
            availability: new Date(),
            availabilityend: new Date(),
            resourceid: 1,
            resource: "",
            resourcetype: "Surplus/Export",
            resourcetypeshort: "",
            quantitydetail: undefined,
            quantitynoinfo:false,
            description:'',
            descriptionnoinfo:false,
            startdate: new Date(),
            enddate:  new Date(),
            stringstartdate: '',
            stringenddate: '',
            timeframestart:'',
            timeframeend:'',
            timeframenoinfo:false,
            trucking:0,
            truckingtype:'',
            materialtipping: 0,
            materialstatus: '',
            perunitload: 1,
            unitload: 'Per Load',
            cost:0,
            user:user,
            username:name,
            mail:mail,
            corporation:corporation,
            phonenumber:phonenumber,
            sharedetail:0,
            site: 0,
            //match: null,
            //matchsiteresource: null,
            sharedetailid:1,
            saveasdefault:false,
            discoverymode: 1,
            //thread:0,
            sitename: "defaultSiteName",
            fromFrontEnd: true,

            showDefaultCheckbox: false,
            loadedDetails: false,
            originalQuantity: 0,
            popupTrigger: false,
            submitting: false,
            sendDetailsToAllExternalMatches: false,
            showSendDetailsToAllExternalMatchesCheckbox: false,
            name
        }
        
    }

    /////////////////////////
    //Loading the component
    /////////////////////////

    componentWillMount = async () => {
        let {idsite_resource} = this.props;
        if (idsite_resource != 0){
            await this.loadMaterial(idsite_resource);
            await this.loadDefaultDetails(idsite_resource);
        }
    }

    componentWillReceiveProps = async (newProps) => {

        /*console.log("componentWillRecieveProps ModalShareDetails with idsite_resource " + newProps.idsite_resource + " and old idsite_resource " + this.props.idsite_resource);

        console.log("componentWillRecieveProps ModalShareDetails First check: ", newProps.idsite_resource != this.props.idsite_resource);
        console.log("componentWillRecieveProps ModalShareDetails Second check: ", newProps.idsite_resource != 0);*/

        if ((newProps.idsite_resource != this.props.idsite_resource && newProps.idsite_resource != 0) || newProps.forceReload !== this.props.forceReload){
            //alert("Populating the modal");
            console.log("componentWillRecieveProps ModalShareDetails Loading new material with idsite_resource " + newProps.idsite_resource);
            await this.loadMaterial(newProps.idsite_resource);
            await this.loadDefaultDetails(newProps.idsite_resource);
        }
    }

    loadMaterial = async (idsite_resource) => {
        this.setState({submitting: false});

        //console.log("Calling loadMaterial with idsite_resource " + idsite_resource);
        let material = await HaulCheckService.getMaterialInfo(idsite_resource, "ModalShareDetails: " + "details-form-"+this.props.idsite_resource+"-"+this.props.parent);
       // console.log("loadMaterial API call result:", material);

        if (material[0] == undefined) return;
        else material = material[0];

        let end_date = material.available_end_date;
        if (!material.available_end_date){
            end_date = material.available_date
        }

        this.setState({
            availability: moment(material.available_date).add('days', 1).format("YYYY-MM-DD"),
            availabilityend: moment(end_date).add('days', 1).endOf('month').format("YYYY-MM-DD"),
            resourceid:material.idresource,
            resource: material.resource,
            resourcetype: material.type,
            resourcetypeshort: material.type.split('/')[0],
            //match: material.idmatch,
            //matchsiteresource: material.idmatchsiteresource,
            discoverymode: material.iddiscoverymode,
            sitename: material.sitename,
            site: material.idsite,
            saveasdefault: (this.props.parent != "conversations"),
            showDefaultCheckbox: /*(this.props.parent == "conversations")*/ false,
            sendDetailsToAllExternalMatches: false,
            showSendDetailsToAllExternalMatchesCheckbox: (this.props.parent == "conversations"),
            loadedDetails: true,
            originalQuantity: material.quantity
        })

        this.moveModalToBody("details-form-"+idsite_resource+"-"+this.props.parent)
        this.moveModalToBody("card-details-"+idsite_resource+"-"+this.props.parent)
    }

    loadDefaultDetails = async (idsite_resource) => {
        let data = await SharesDetailsService.getDefaultMaterialDetail(idsite_resource);

        //console.log("loadDefaultDetails details:", data);

        if (data && data.length > 0){
            data[0].startdate = new Date(data[0].startdate);
            data[0].startdate.setDate(data[0].startdate.getDate() + 1);
            if (data[0].enddate){
                data[0].enddate = new Date(data[0].enddate);
                data[0].enddate.setDate(data[0].enddate.getDate() + 1);
            } else {
                data[0].enddate =new Date(data[0].startdate);
                data[0].enddate.setDate(data[0].startdate.getDate() + 1);
            }
            
            if (data[0].quantity == null){
                data[0].quantitydetail = undefined;
            } else {
                data[0].quantitydetail = data[0].quantity;
            }

            if (!data[0].cost){
                data[0].cost = 0;
            }

            this.setState(data[0]);
        } else {
            let startdate = new Date();
            let enddate = new Date();

            if (this.state.availability){
                startdate = new Date(this.state.availability);
                startdate.setDate(startdate.getDate() + 1);

                if (this.state.availabilityend){
                    enddate = new Date(this.state.availabilityend);
                    enddate.setDate(enddate.getDate() + 1);
                } else if (this.state.availability){
                    enddate = new Date(this.state.availability);
                    enddate.setDate(enddate.getFullYear(), enddate.getMonth()+2, 0);
                }
            }

            this.setState({
                quantity: this.state.originalQuantity,
                startdate,
                enddate,
                quantitydetail: undefined,
                quantitynoinfo:false,
                description:'',
                descriptionnoinfo:false,
                stringstartdate: '',
                stringenddate: '',
                timeframestart:'',
                timeframeend:'',
                timeframenoinfo:false,
                trucking:0,
                truckingtype:'',
                materialtipping: 0,
                materialstatus: '',
                perunitload: 1,
                unitload: 'Per Load',
                cost:0,
          
                sharedetail:0,
                saveasdefault: (this.props.parent != "conversations"),
                loadedDetails: false,
            })
        }
    }

    /////////////////////////
    // Handling State changes
    /////////////////////////

    handleChange = (key, val) => {
        //console.log("Calling handleChange with key: " + key + " and val:",val)
        this.setState({[key]: val});
    }

    toggleCheckBox = (e, key) => {
        e.preventDefault();
        //console.log("Calling toggleCheckBox with key " + key + "and val:", !this.state[key])
        this.setState({[key]: !this.state[key]})
    }

  handleOptionPer = e => {
    let  desper=''
    if(e.target.value == 1){
      desper='Per Load'
    }
    if(e.target.value == 2){
      desper='Per Ton'
    }
    if(e.target.value == 3){
      desper='Per CY'
    }
    if(e.target.value == 4){
      desper='TBD'
    }
    //console.log(e.target, e.target.value)
    this.setState({
        perunitload: e.target.value,
        unitload:desper,
    })
  }

  handleOptionChangeTrucking = (e) => {
    let  destruckingtype=''
    if(e.target.value === "1"){
      destruckingtype='by Others'
    }
    if(e.target.value === "2"){
      destruckingtype='by Me'
    }
    if(e.target.value === "3"){
      destruckingtype='Shared'
    }
    if(e.target.value === "4"){
      destruckingtype='No info'
    }

    this.setState({
        trucking: e.target.value,
        truckingtype:destruckingtype,
    })
  }

  handleOptionChangeTipping = (e) => {
    let  desmaterialtipping=''
    if(e.target.value == "1"){
      desmaterialtipping='I\'m charging'
    }
    if(e.target.value == "2"){
      desmaterialtipping='I\'m not charging'
    }
    if(e.target.value == "3"){
      desmaterialtipping='No info'
    }
    this.setState({
        materialtipping: e.target.value,
        materialstatus:desmaterialtipping,
    })
  }

  handleDateChange = (date, d) => {

    if (d === 0) {
      this.setState({
          startdate: date,
          stringstartdate: moment(date).format("MMMM D, YYYY"),
      })
    } else {
      this.setState({
          enddate: date,
          stringenddate: moment(date).format("MMMM D, YYYY"),
      })
    }
  }


    /////////////////////////
    // Submitting the data
    /////////////////////////

    submit = async () => {

        this.setState({submitting: true});

        let data = JSON.parse(JSON.stringify(this.state));
        let {callback, idsite_resource, parent, idpmgroup} = this.props;

        data.startdate = moment(data.startdate).format("YYYY-MM-DD");
        data.enddate = moment(data.enddate).format("YYYY-MM-DD");
        data.idsite_resource = idsite_resource;

        if (data.trucking == 0){
            data.trucking = 4;
            data.truckingtype = "No info";
        }

        if (data.materialtipping == 0){
            data.materialtipping = 4;
            data.unitload = "TBD";
        }

        if (!data.cost){
            data.cost = 0;
        }

        if (typeof data.quantitydetail == "string"){
            data.quantitydetail = data.quantitydetail.replaceAll(",", ""); 
        }

        if (data.sendDetailsToAllExternalMatches){
            await SharesDetailsService.sendDetailsToAllExternalMatches(data);
        }

        let returnData = await SharesDetailsService.saveMaterialDetail(data); //Save the details as the main shared details for the material
        if (callback){ 
            if (returnData){
                returnData.quantitydetail = returnData.quantity;
                returnData.sendDetailsToAllExternalMatches = data.sendDetailsToAllExternalMatches;
                console.log("ModalShareDetails callback with returnData", returnData);
                callback(returnData);
            } else {
                console.log("ModalShareDetails callback with front-end data", data);
                callback(data);
            }
        } //Send the data to the callback to eg update the parent component
        await SharesDetailsService.setStartEndDateForSiteResource(data.startdate, data.enddate, data.idsite_resource); //Update the availability dates for the material
        //await SharesDetailsService //Update the default details, if neeeded
        this.loadMaterial(idsite_resource); //Reset the state
        if (!this.state.loadedDetails){
            this.setState({popupTrigger: true})
        }

        let closeButtons = document.getElementsByClassName("close-modal-preview-"+idsite_resource+"-"+parent);
        for(let i = 0; i < closeButtons.length; i++){
            if (closeButtons[i]) closeButtons[i].click();
        }

    }

    /////////////////////////
    //Rendering
    /////////////////////////

    moveModalToBody = (modalId) => {
        let modal= document.querySelector("#"+modalId);
        let body = document.querySelector("body");
        body.appendChild(modal);
    }

    render = () => {

        let {parent, idsite_resource} = this.props;
        let {resource, resourcetypeshort, 
            startdate, enddate, timeframenoinfo,
            quantitydetail, quantitynoinfo, 
            description, descriptionnoinfo, 
            trucking, truckingtype, 
            materialtipping, cost, perunitload,
            saveasdefault, sitename, originalQuantity,
            loadedDetails, showDefaultCheckbox, 
            showSendDetailsToAllExternalMatchesCheckbox, sendDetailsToAllExternalMatches, submitting} = this.state

        let today = new Date();

        return(<Fragment>

        <button type="button" style={{display:'none'}} id={"open-details-form-"+idsite_resource+"-"+parent} data-toggle="modal" data-target={"#details-form-"+idsite_resource+"-"+parent}></button>

        <div class="modal fade" id={"details-form-"+idsite_resource+"-"+parent} tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="alert alert-primary" role="alert">
                        <strong>{parent == "conversations" ? "Share" : "Enter"}  Details</strong>
                        <button type="button" className={"close close-modal-preview-"+idsite_resource+"-"+parent} data-dismiss="modal" aria-label="Close" id={"close-modal-preview-"+idsite_resource+"-"+parent}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body pt-2 px-3">

                        <div class="col-12 float-left border-bottom mb-4">
                            <div class="lead-1 mb-1 float-left">
                                <strong>
                                    {resource} {resourcetypeshort} {parseInt(originalQuantity).toLocaleString()}s CYs — {this.renderAvailability()}
                                </strong>
                            </div> 
                        </div>

                        <form class="mt-3 mb-4">

                            <div class="col-11 p-0 mt-1 ml-4 float-left">
                                <label class="text-dark">Description</label>
                                <a tabindex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="Describe your need" data-content={"For example, tell your "+(parent == "conversations" ? "match" : "Group")+" how granular, cohesive, or compactable your dirt is, or if you need liner clay."}>
                                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                                </a>
                                <div class="form-group mb-0">
                                    <textarea
                                    id={`description_${idsite_resource}`}
                                    className="form-control ml-0"
                                    placeholder="Provide additional info about your resource"
                                    rows="2"
                                    value={description}
                                    onChange={e => this.handleChange("description", e.target.value)}
                                    ></textarea>
                                </div>
                            </div>

                            <div class="col-12 row pt-4 ml-1">

                                <div class="col-4 pl-0 clearfix">
                                    <label class="text-dark">Specific Quantity</label>
                                    <a tabindex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="Specific Quantity" 
                                    data-content={(parent == "conversations" ? 
                                        "This will be shared with only this match unless you check the box below. It will not change the General Quantity that is shown to other external matches."
                                        : 
                                        "This will automatically be shared within your Group. It will not change the General Quantity that is shown to external matches. You can share this with your external matches via messaging.")}>                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    </a>
                                    <div class="col-12 pl-0">
                                        <div class="input-group">
                                            <input
                                            id={`quantity_${idsite_resource}`}
                                            name='amount_detail'
                                            type="text" className="form-control"
                                            aria-label="Amount (to the nearest hundred)"
                                            value={quantitydetail}
                                            onChange={e => this.handleChange("quantitydetail", e.target.value)}
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text">CYs</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="custom-control custom-checkbox pt-2" onClick={(e) => this.toggleCheckBox(e, "quantitynoinfo")}>
                                        <input
                                        id={`quantitynoinfo_${idsite_resource}`}
                                        type="checkbox"
                                        className="custom-control-input"
                                        checked={quantitynoinfo}
                                        />
                                        <label className="custom-control-label fs-11" htmlFor={`quantitynoinfo_${idsite_resource}`}>No info</label>
                                    </div>
                                </div>

                                <div class="col-8 p-0">
                                    <label class="text-dark">Specific Availability</label>
                                    <a tabindex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="Specific Availability" 
                                    data-content={(parent == "conversations" ? 
                                        "This will be shared with only this match. It will not change the General Availability that is used to filter external matches."
                                        :
                                        "This will automatically be shared within your Group. It will not change the General Availability that is used to filter external matches. You can share this with your external matches via messaging.")}>
                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    </a>
                                    <div class="input-daterange input-group mr-2" id="datepicker">
                                        <span className="input-group-addon pt-2 pl-3">From</span>
                                        <DatePicker
                                        locale="en-US"
                                        className="input-sm form-control"
                                        selected={ startdate || today }
                                        selectsStart
                                        startDate={ startdate || today }
                                        endDate={ enddate || today }
                                        dateFormat="MM/dd/yyyy"
                                        onChange={ date => this.handleDateChange(date, 0) }
                                        popperModifiers={{
                                            preventOverflow: {
                                              enabled: true,
                                            },
                                          }}
                                        />
                                        <span className="input-group-addon pt-2">to</span>
                                        <DatePicker
                                        locale="en-US"
                                        className="input-sm form-control"
                                        selected={ enddate || today }
                                        selectsEnd
                                        startDate={ startdate || today }
                                        endDate={ enddate || today }
                                        dateFormat="MM/dd/yyyy"
                                        onChange={ date => this.handleDateChange(date, 1) }
                                        popperModifiers={{
                                            preventOverflow: {
                                              enabled: true,
                                            },
                                          }}
                                        />  
                                    </div>
                                </div>
                            </div>


                               

                            <div class="col-12 mt-4 clearfix">

                                <div class="col-4 p-0 float-left">
                                    <label class="text-dark">Trucking</label>

                                    <div class="custom-controls-stacked">
                                        <div className="custom-control custom-radio" onClick={() => this.handleOptionChangeTrucking({target: {value: 1}})}>
                                            <input id="byothers" type="radio" className="custom-control-input" name="trucking"
                                            value="1"
                                            checked={trucking === '1' || trucking === 1 }
                                            />
                                            <label className="custom-control-label" htmlFor="byothers">by Others</label>
                                        </div>
                                        <div className="custom-control custom-radio" onClick={() => this.handleOptionChangeTrucking({target: {value: 2}})}>
                                            <input id="byme" type="radio" className="custom-control-input" name="trucking"
                                            value="2"
                                            checked={trucking === '2' || trucking === 2 }
                                            />
                                            <label className="custom-control-label" htmlFor="byme">by Me</label>
                                        </div>
                                        <div className="custom-control custom-radio"  onClick={() => this.handleOptionChangeTrucking({target: {value: 3}})}>
                                            <input id="shared" type="radio" className="custom-control-input" name="trucking"
                                            value="3"
                                            checked={trucking === '3' || trucking === 3 }
                                            />
                                            <label className="custom-control-label" htmlFor="shared">Shared</label>
                                        </div>
                                        <div className="custom-control custom-radio"  onClick={() => this.handleOptionChangeTrucking({target: {value: 4}})}>
                                            <input id="noinfo" type="radio" className="custom-control-input" name="trucking"
                                            value="4"
                                            checked={trucking === '4' || trucking === 4 }
                                            />
                                            <label className="custom-control-label" htmlFor="noinfo">No Info</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 p-0 float-left">
                                    <label class="text-dark">Materials & Tipping Cost</label>
                                    <div className="custom-controls-stacked">
                                        <div className="custom-control custom-radio" onClick={() => this.handleOptionChangeTipping({target: {value: 1}})}>
                                            <input
                                            id="charging" name="materialt"
                                            type="radio"
                                            className="custom-control-input"
                                            value="1"
                                            onChange={e => this.handleOptionChangeTipping(e)}
                                            checked={materialtipping === 1 || materialtipping === '1'}
                                            />
                                            <label className="custom-control-label" htmlFor='charging'>I'm charging...</label>
                                        </div>
                                        <div className="accordion accordion-light col-12" id="charge-details">
                                            <div className="card noborder" style={{borderStyle: 'none'}}>
                                                <div id="open-charges" className={"collapse" + (materialtipping === 1 || materialtipping === "1" ? " show" : "")}>
                                                    <div className="card-body py-2">
                                                        <div className="input-group mb-1">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                id={`cost_${idsite_resource}`}
                                                                name='cost'
                                                                type="text" className="form-control"
                                                                aria-label="Amount (to the nearest dollar)"
                                                                value={cost}
                                                                onChange={e => this.handleChange("cost", e.target.value)}
                                                            />
                                                        </div>
                                                        <select className="form-control form-control-sm"
                                                        id={`per_${idsite_resource}`}
                                                        name='per'
                                                        value={ perunitload }
                                                        onChange={e => this.handleOptionPer(e)}>
                                                            <option value="1" key='1'>Per Load</option>
                                                            <option value="2" key='2'>Per Ton</option>
                                                            <option value="3" key='3'>Per CY</option>
                                                            <option value="4" key='4'>TBD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-control custom-radio"  onClick={() => this.handleOptionChangeTipping({target: {value: 2}})}>
                                            <input
                                            id="notcharging" name="materialt"
                                            type="radio"
                                            className="custom-control-input"
                                            value="2"
                                            onChange={ e => this.handleOptionChangeTipping(e) }
                                            checked={materialtipping === 2 || materialtipping === '2'}
                                            />
                                            <label className="custom-control-label" htmlFor='notcharging'>I'm not charging</label>
                                        </div>

                                        <div className="custom-control custom-radio" onClick={() =>this.handleOptionChangeTipping({target: {value: 3}})}>
                                            <input
                                            id="chargingnoinfo" name="materialt"
                                            type="radio"
                                            className="custom-control-input"
                                            value="3"
                                            onChange={e => this.handleOptionChangeTipping(e)}
                                            checked={materialtipping === 3 || materialtipping === '3'  }
                                            />
                                            <label className="custom-control-label" htmlFor='chargingnoinfo'>No Info</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 pl-4 mt-6 pt-4 float-left border-top">
                                    <label class="text-dark">{/*showDefaultCheckbox ? "Save As Default" : "Auto-Save"*/}Save</label>
                                    {/*<a tabindex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="Just in case" data-content="And here's some amazing content. It's very engaging. Right?">
                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    </a>*/}
                                    {/*showDefaultCheckbox ?
                                    <div className="custom-control custom-checkbox col-12" onClick={(e) => this.toggleCheckBox(e, "saveasdefault")}>
                                        <label className="small">These details will only be shared with this match unless you specify otherwise.</label>
                                        <input
                                        id={`saveasdefault_${idsite_resource}`}
                                        type="checkbox" className="custom-control-input" name="save-check"
                                        checked={saveasdefault}
                                        />
                                        <label className="custom-control-label" htmlFor={`saveasdefault_${idsite_resource}`}>Save this as the default details that are auto-shared with my Office Group</label>
                                    </div> :*/}
                                    <label className="small">
                                        {loadedDetails ? 
                                        "These details will be saved as your default details and will automatically be shared with your Office Group."
                                        :
                                        "These details will be saved as your default details and will automatically be shared with your Office Group. You will have the option to edit them later and share manually share them with external matches"
                                        }
                                    </label>
                                    {/*}*/}
                                </div>

                                {showSendDetailsToAllExternalMatchesCheckbox ? <div class="col-12 pl-4 mt-6 pt-4 float-left border-top">
                                    <label class="text-dark">Share with all Matches</label>
                                    <a tabindex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="Just in case" data-content="And here's some amazing content. It's very engaging. Right?">
                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    </a>
                                    <div className="custom-control custom-checkbox col-12" onClick={(e) => this.toggleCheckBox(e, "sendDetailsToAllExternalMatches")}>
                                        <input
                                        id={`sendDetailsToAllExternalMatches_${idsite_resource}`}
                                        type="checkbox" className="custom-control-input" name="send-to-all"
                                        checked={sendDetailsToAllExternalMatches}
                                        />
                                        <label className="custom-control-label" htmlFor={`sendDetailsToAllExternalMatches_${idsite_resource}`}>Send these details to all of my matches</label>
                                    </div>
                                </div> : null}
                            </div>

                        </form>
                    </div>

                    {parent == "conversations" ?
                        <a class="btn btn-primary text-white" href="#" onClick={() => this.moveModalToBody("card-details-"+idsite_resource+"-"+parent)} data-toggle="modal" data-target={"#card-details-"+idsite_resource+"-"+parent} data-dismiss="modal">PREVIEW DETAILS</a>
                    :
                        <a class="btn btn-primary text-white" href="#" onClick={this.submit}>{this.state.submitting ? <img src="../../img/app/photoswipe/preloader.gif" /> : null}SAVE DETAILS</a>
                    }

                </div>
            </div>
        </div>


        <div class="modal fade" id={"card-details-"+idsite_resource+"-"+parent} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-gray px-4 pt-2 pb-0">
                        <p class="modal-title strong" id="exampleModalLabel">Share Details</p>
                        <button type="button" class="close pt-5" data-dismiss="modal" aria-label="Close" id={"close-modal-preview-"+idsite_resource+"-"+parent}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                    <p>This is what you are about to share:</p>

                        <div class="card border mx-auto bg-gray pr-0 pl-0">
                            <div class="card-body bg-gray">
                                <div class="media-body">                    
                                    <div className="lead mb-1"><strong>{resource} {resourcetypeshort}</strong></div>
                                    <SharedDetailsDisplay sharedDetailsObj={this.state} mode="messagethread" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">

                        <button type="button" className="btn btn-secondary" /*data-toggle="modal" data-target={"#details-form-"+idsite_resource+"-"+parent}*/ data-dismiss="modal">Back</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.submit}>{this.state.submitting ? <img src="/img/app/photoswipe/preloader.gif" /> : null}Share Details</button>
                    </div>
                </div>
            </div>
        </div>


        <SuccessPopup
            popupId="popupDetailsUpdated"
            title="Success!"
            message="Details updated!"
            trigger={this.state.popupTrigger && loadedDetails}
            callback={() => {this.setState({popupTrigger: false})}}
        />

        </Fragment>)


    }

    renderAvailability = () => {

        let {availability, availabilityend} = this.state;
        let specificDateFormat = "MMM DD";
        let generalDateFormat = "MMMM"

        if (availability && ! availabilityend){
            return "Available " + moment(availability).add("days", 1).format(generalDateFormat);
        } else if (!availability && availabilityend){
            return "Available until " + moment(availabilityend).subtract("days", 5).format(generalDateFormat);
        } else if (!availability && ! availabilityend){
            return "Availability info not specified"
        } else {
            return "Available " + moment(availability).add("days", 1).format(generalDateFormat) + " ‒ " + moment(availabilityend).subtract("days", 5).format(generalDateFormat);
        }
    }


}