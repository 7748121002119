import React, {Component, Fragment}  from 'react';
import {url, earthidealAPIURL} from '../../constants';
import jwtDecode from 'jwt-decode';
import MaterialRow from './MaterialRow';
import ExternalMatchRow from './ExternalMatchRow';
import MyMaterial from './MyMaterial';
import OpenMaterialInfoButton from "./OpenMaterialInfoButton"
import SandboxMap from '../SandboxMap/SandboxWrapper'
import OptInModal from '../SiteMaterials/OptInToPayment/OptInModal'
import MobileDisplaySwitcher from '../common/MobileDisplaySwitcher'
import { Popover, OverlayTrigger } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import '../MySites/styles.css'
import StatusBar from './StatusBar'
import ShareDetailsAllButton from './ShareDetailsAllButton'

import {
  ApiService,
  HaulCheckService,
  PMGroupsService,
  SharesDetailsService
  //MatchesService,
  //ConversationsService
} from '../../services'

export default class InHouse extends Component {

  constructor(props){
    super(props);
    const userJWT = localStorage.getItem('token');
    const userDecoded = jwtDecode(userJWT);

    let username = userDecoded['cognito:username'];
    let email = userDecoded.email;

    this.state = {
      materials: {mine: {idsite_resource: 0}, same: [], match: []},
      email,
      group: {},
      siteData: {geom: {x: null, y: null}},
      modalInviteIsOpen: false,
      archiveMode: false,
      //externalMatches: [],
      reloadSharedDetails: false,
      bidGraveyardList: [],
      sameList: [],
      //matchList: [],
      loading: true,
      lastIdSiteResource: 0,
      toggleDetailsToHidden: true,
      bidsNum: 0,
      postsNum: 0,

      internalsBid: [],
      externalsBid: [],
      internalsPost: [],
      externalsPost: [],
      routes: [],
      routesMapBounds: [],
      tallyData: null,
      postingExternally: false,

      panelDisplayState: "matches",
      hasExternalAccess: false,
    } 
  }

  componentWillReceiveProps = async (newProps) => {
    let {lastIdSiteResource} = this.state;

    if (newProps.idsite_resource != lastIdSiteResource && newProps.idsite_resource != 0 && newProps.idsite != 0){
      this.refreshPanel(newProps.idsite_resource, newProps.idsite, newProps.matches, "componentWillReceiveProps");
      this.setState({toggleDetailsToHidden: true}, () => console.log("BUG-1046", "componentWillReceiveProps render"));
    }
  }

  componentDidMount = async () => {
    let hasExternalAccess = await ApiService.getUserHasExternalAccess();
    this.setState({hasExternalAccess});

    if (this.props.idsite_resource != 0 && this.props.idsite != 0){
      this.refreshPanel(this.props.idsite_resource, this.props.idsite, this.props.matches, "componentDidMount");
      this.setState({toggleDetailsToHidden: true}, () => console.log("BUG-1046", "componentDidMount render"));
    }
  }

  componentDidUpdate = () => {
    ReactTooltip.rebuild();
  }

  componentWillUnmount = () => {
    console.log("Ghostly Overhang Debug", "Calling InHouse componentWillUnmount and clearing its routes");
    this.setState({routes: []});
    this.removeInterval();
  }

  createInterval = (idsite_resource, idsite, matches) => {
    console.log("Calling createInterval with params: ",idsite_resource, idsite, matches)
    this.removeInterval();

    this.interval = setInterval(() => {
      this.refreshPanel(idsite_resource, idsite, matches, "interval");
    }, 15 * 1000)
  }

  removeInterval = () => {
    console.log("Calling removeInterval with interval", this.interval);
    if (this.interval) clearInterval(this.interval);
  }

  refreshPanel = async (idsite_resource, idsite, matches, origin) => {
    console.log("showSiteRoutes debug", "calling refreshPanel with params", idsite_resource, idsite, matches, origin)
    this.createInterval(idsite_resource, idsite, matches);
    let {lastIdSiteResource} = this.state;

    let tallyViews = (origin == "componentDidMount" || origin == "componentWillReceiveProps")
    //console.log("InHouse lastIdSiteResource:", lastIdSiteResource, "idsite_resource param:", idsite_resource);
    //console.log("InHouse idsite_resource != lastIdSiteResource:", idsite_resource != lastIdSiteResource)
    let loading = (idsite_resource != lastIdSiteResource);
    this.setState({loading, lastIdSiteResource: idsite_resource}, () => console.log("BUG-1046", "refreshPanel render start loading"));

    await this.getMaterials(idsite_resource, idsite, tallyViews);
    let routes = await this.showSiteRoutes({idsite_resource, idsite}, origin);
    let tallyData = null;
    if (this.state.hasExternalAccess) {
      await this.getExternalMatches(idsite_resource, idsite)
      tallyData = await HaulCheckService.getExternalMatchTally(idsite_resource);
    }

    //console.log("refreshPanel internals:", internals);
    //console.log("refreshPanel externals:", externals);

    let oldState = {loading: this.state.loading, tallyData: this.state.tallyData, postingExternally: this.state.postingExternally}
    let newState = {loading: false, tallyData, postingExternally: false}

    if (!_.isEqual(newState, oldState)) {
      this.setState(newState, () => console.log("refreshPanel setState with newState", newState, "and oldState", oldState));
    } else {
      console.log("refreshPanel not setting state with newState", newState, "and oldState", oldState)
    }

  }

  showSiteRoutes = (material, origin) => {
    console.log("showSiteRoutes debug", "Calling showSiteRoutes with material", material, "from origin", origin);

    fetch(`${url.backend}/sites/getRoutesForMaterial/${material.idsite_resource}/${material.idsite}`)
      .then(res =>res.json())
      .then(results => {
        console.log("results for showSiteRoutes:", results)
        let  { routes, uniqueDestinations, coordBounds } = results
        routes = _.cloneDeep(routes);

        console.log("Routes in showSiteRoutes:", routes);
        console.log("uniqueDestinations in showSiteRoutes:", uniqueDestinations);
        console.log("coordBounds in showSiteRoutes:", coordBounds);

        if (this.state.routes && this.state.routes.length > 0 && this.state.routes[0].idsite_resource == this.props.idsite_resource){
          let stateRoutesIds = this.state.routes.map(({idroutedistance}) => {return idroutedistance});

          for (var i = 0; i < routes.length; i++){
            let index = stateRoutesIds.indexOf(routes[i].idroutedistance);

            if (index != -1){
              routes[i].visible = this.state.routes[index].visible;
            }
          }
        }

        //this.formatRoutesDebug(routes, material.idsite_resource);
        if (!_.isEqual(this.state.routes, routes)){
          this.setState({routes: []}, () => this.setState({routes: _.cloneDeep(routes)}, () => console.log("showSiteRoutes re-rendering with new routes", routes, "and original routes", this.state.routes)));
        } else {
          console.log("showSiteRoutes not refreshing with original routes", this.state.routes, "and new routes", routes)
        }

        if (!_.isEqual(this.state.routesMapBounds, coordBounds)){
          this.setState({routeMapBounds: []}, () => this.setState({routesMapBounds: coordBounds}))
        }
    })
  }

  formatRoutesDebug = (routes, idsite_resource) => {
    console.log("formatRoutesDebug", "------------------------------");
    console.log("formatRoutesDebug", "Routes for material " + idsite_resource);
    if (routes.length == 0){
      console.log("formatRoutesDebug", "No routes to output");
    } else {
      for (var i = 0; i < routes.length; i++){
        console.log("formatRoutesDebug", "=========== " + i + " ===========")
        console.log("formatRoutesDebug", "name of site:", routes[i].name);
        console.log("formatRoutesDebug", "visible", routes[i].visible);
        //console.log("formatRoutesDebug", "unique site id:", routes[i].idsite_destination);
        console.log("formatRoutesDebug", "internal: ", routes[i].internal_match);
        //console.log("formatRoutesDebug", "time: ", routes[i].time);
        //console.log("formatRoutesDebug", "partner_discovery_mode:", routes[i].partner_discovery_mode)
        console.log("formatRoutesDebug", "parter_archived:", routes[i].partner_archived);
        console.log("formatRoutesDebug", "labeled_partner:", routes[i].labeled_partner);
        //console.log("formatRoutesDebug", "partner_site_archived:", routes[i].partner_site_archived);
        //console.log("formatRoutesDebug", "match_was_visible:", routes[i].match_was_visible)
      }
    }
  }

  getMaterials = async (idsite_resource, idsite, tallyViews) => {
    let {email} = this.state;

    ////console.log("InHouse recieving props:", email, idsite_resource);

    console.log("calling getResourceCompanyImbalances from mySites/getMaterials");
    let {materials, pmGroup, siteInfo} = await PMGroupsService.getResourceCompanyImbalances(email, idsite_resource, tallyViews);
   // //console.log("InHouse materials:", materials );
    console.log("siteInfo:", siteInfo);
    let {bidGraveyardList, /*same,*/ matchPost, matchBid} = materials;

    let newState = {};

    if (!_.isEqual(this.state.internalsBid, matchBid)) newState.internalsBid = matchBid;
    if (!_.isEqual(this.state.internalsPost, matchPost)) newState.internalsPost = matchPost;
    if (!_.isEqual(this.state.bidGraveyardList, bidGraveyardList)) newState.bidGraveyardList = bidGraveyardList;

    if (!_.isEqual(this.state.siteData, siteInfo[0])) newState.siteData = siteInfo[0];
    if (!_.isEqual(this.state.group, pmGroup)) newState.group = pmGroup;
    if (!_.isEqual(this.state.archiveMode, materials.mine.archived)) newState.archiveMode = materials.mine.archived;
    if (!_.isEqual(this.state.materials, materials)) newState.materials = materials;

    if (!_.isEmpty(newState)){
      this.setState(newState, () => console.log("getMaterials re-rendering with new state",newState))
    } else {
      console.log("getMaterials not re-rendering with new state", newState)
    }



    /*this.setState({
        //sameList: same, 
        internalsBid: matchBid,
        internalsPost: matchPost,
        bidGraveyardList,

        siteData: siteInfo[0], 
        group: pmGroup, 
        archiveMode: materials.mine.archived,
        materials: materials
    }, () => console.log("BUG-1046", "getMaterials render"));*/
  }

  /* Load and process external matches */

  getExternalMatches = async(idsite_resource, idsite) => {
    console.log("Calling getExternalMatches for params ", idsite_resource, idsite);
    let externals = fetch(`${url.backend}/matches/getexternalmatchsbyidsiteresource/${idsite_resource}`)
    .then(res =>res.json())
    .then(results => {
      let {matchesListPost, matchesListBid} = results;
      let newState = {};

      if (!_.isEqual(this.state.externalsPost, matchesListPost)) newState.externalsPost = _.cloneDeep(matchesListPost);
      if (!_.isEqual(this.state.externalsBid, matchesListBid)) newState.externalsBid = _.cloneDeep(matchesListBid);

      if (!_.isEmpty(newState)){
        this.setState(newState, () => console.log("BUG-1046", "getExternalMatches state update with new state", newState))
      } else {
        console.log("Calling getExternalMatches but not updating")
      }

      //this.setState({externalsPost: matchesListPost, externalsBid: matchesListBid}, () => console.log("BUG-1046", "getExternalMatches render"));
    })

    return externals;
  }

  updateMyDetails = (details) => {
    let {materials} = _.cloneDeep(this.state);
    materials.mine.details[0] = details;
    this.setState({materials}, () => console.log("BUG-1046", "updateMyDetails render"));
  }

  getBadgeColor = (number, toReturn) => {

    if (number == 0){
      return " badge-secondary"
    } else {
      if (toReturn) return " " + toReturn;
      else return " badge-success badge-glass"
    }

  }

  toggleSharedDetailsReload = () => {
    this.setState({reloadSharedDetails: !this.state.reloadSharedDetails}, () => console.log("BUG-1046", "toggleSharedDetailsReload render"))
  }


  toggleRouteVisible = (idroutedistance) => {
    if (idroutedistance == null) return;
    else {
      let {routes} = this.state;
      let {idsite_resource} = this.props;

      console.log("BUG-864", "Calling toggleRouteVisible with idroutedistance" + idroutedistance + " and routes " + routes);

      for (var i = 0; i < routes.length; i++){
        if (routes[i].idroutedistance == idroutedistance){
          console.log("BUG-864", "toggling route " + idroutedistance + " to " + !routes[i].visible);
          routes[i].visible = !routes[i].visible;
          routes[i] = _.cloneDeep(routes[i]);
        }
      }

      this.setState({routes: []}, () => this.setState({routes: _.cloneDeep(routes)}, () => console.log("BUG-1046", "toggleRouteVisible render")));
    }
  }

  getRouteExistsAndIsVisible = (idroutedistance) => {

    let exists = false;
    let isVisible = false;

    if (idroutedistance != null) {
      let {routes} = this.state;
      console.log("BUG-864", "Calling getRouteIsVisible with idroutedistance" + idroutedistance + " and routes " + routes);

      for (var i = 0; i < routes.length; i++){
        if (routes[i].idroutedistance == idroutedistance && (routes[i].idsite_origin != routes[i].idsite_destination)){
          exists = true;
          if (routes[i].visible){
            isVisible = true;
          }
        }
      }
    }

    return {exists, isVisible}
  }

  RowWrapper = (props) => {
    let {isBrowser, isMobile} = this.props;
      if (isBrowser){
        return <>
          {/*<div className="card border">
            <div className="card-body px-4 pt-1 pb-0">*/}
              {props.children}
            {/*</div>
          </div>*/}
        </>
      } else if (isMobile){
        return <>{props.children}</>
      }
    }

  EmptyStateWrapper = (props) => {
    let {isBrowser, isMobile} = this.props;
      if (isMobile){
        return <>
          <div className="card border">
            <div className="card-body px-4 pt-1 pb-0">
              {props.children}
            </div>
          </div>
        </>
      } else if (isBrowser){
        return <>{props.children}</>
      }
    }

    HeaderLabel = ({showTally, tally, tallyColor, showToggle, isTopHeader, labelText, sideButton}) => {
      let {toggleDetailsToHidden} = this.state;

      if (!showTally && !showToggle){
        return <label className="strong lead fs-17 mt-4" style={{width: '100%'}}>{labelText}</label>
      }

      else return <div className="d-flex mt-4">
        <label className="lead fs-17 my-3">{labelText}</label>
        {showTally ? 
          <a className="small level-badge mt-4 mr-auto">
            <span class={"badge badge-number fs-10 strong pt-1 px-1 ml-2 "+this.getBadgeColor(tally, tallyColor)}>{tally}</span>
          </a> 
        : null}

        {sideButton ? sideButton : null}
        
        {/*<a onClick={showToggle ? this.toggleAllShowDetails : null} href={showToggle ? "#" : ""} className="ml-auto p-2">
          {showToggle ? <label className="fs-11 fs-200 text-light cursor float-right mt-3">
            {toggleDetailsToHidden ? "Hide All Details" : "Show All Details"}
          </label> : null}
        </a>*/}
      </div>

    }

    renderHeaderView = (children) => {
      let {isMobile, isBrowser} = this.props;
      let {panelDisplayState} = this.state;

      if (isMobile){
        if (panelDisplayState == "header"){
          return  <section class="section py-0 shadow-2">
            <div class="bg-white">
              <div class="col-12 pt-3 px-lg-6 ">
                <div class="row">
                  <div class="col-12 mx-auto mb-8">  
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </section>
        } else {
          return null;
        }
      } else {
        return children;
      }
    }

    goBack = () => {
      this.props.history.push("/app/sites");
    }

    renderMatchesView = (children) => {

      let {isMobile, isBrowser} = this.props;
      let {panelDisplayState} = this.state;

      if (isMobile){
        if (panelDisplayState == "matches"){
         return  <main className="main-content">
            <section className="section py-0 shadow-2">
              <div className="bg-light">
                <div className="col-12 pt-3 px-lg-6 ">
                  <div className="row">
                    <div className="col-12 mx-auto mb-8">  
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        } else {
          return null;
        }
      }

      else if (isBrowser){
        return <div className="col-6 bg-white my-sites-scroll">
          <div className="col-12">

          <div className="bg-white border-bottom px-2">
            <p className="lead strong pt-1 pb-2 mb-0"><a className="text-light strong" href="#" onClick={this.goBack}><i className="fa fa-caret-left mr-2" aria-hidden="true"></i>Sites</a></p>
          </div>

            <div className="row">
              <div className="col-12 mx-auto mb-8"> 
                {children}
              </div>
            </div>
          </div>
        </div>
      }
    }

    renderSandboxView = (children) => {

      let {isMobile, isBrowser} = this.props;
      let {panelDisplayState} = this.state;

       if (isMobile){
        if (panelDisplayState == "routes"){
          return <main className="main-content">

            <section className="section py-0 shadow-2">
              <div className="row no-gutters">
    

                <div className="col-12">
                   <section className="section routes-map">
                    {children}
                   </section>
                </div>
              </div>
            </section>
          </main>
        } else {
          return null;
        }
      }

      if (isBrowser){
        return  <div className="col-6 sandbox my-sites-scroll" id={"routes-map-"+this.props.idsite_resource}>
          {children}
        </div>
      }
    }


  renderStatusBar = () => {
    let material = this.state.materials.mine; //Don't put the {} here
    let {isMobile, isBrowser} = this.props;

    if (this.state.hasExternalAccess){

      return <StatusBar
        material={material}
        isBlasted={material.isBlasted == 1}
        idsite_resource={material.idsite_resource}
        idsite={material.idsite}
        iddiscoverymode={material.iddiscoverymode}
        status={material.status}
        has_ever_been_external={material.has_ever_been_external}
        external_start_date={material.external_start_date}
        expiration_date={material.expiration_date}
        finalExternalMatchTally={this.state.tallyData ? this.state.tallyData.finalExternalMatchTally : -1}
        refreshPanel={() => this.refreshPanel(this.props.idsite_resource, this.props.idsite, this.props.matches, "myMaterial")}
        isMobile={isMobile}
        isBrowser={isBrowser}
      />
    } else return null;
  }

  renderHeader = () => {
    let {isMobile, isBrowser} = this.props;

    return <MyMaterial
      material={this.state.materials.mine}
      toggleStatus={this.toggleStatus}
      openModalInvite={this.openModalInvite}
      expiration_date={this.props.expiration_date}
      toggleSharedDetailsReload={this.toggleSharedDetailsReload}
      isMobile={isMobile}
      isBrowser={isBrowser}
      backToSandbox={() => this.props.switchSidePanel(isMobile ? "sidebar" : "sandbox")}
      refreshPanel={() =>  this.refreshPanel(this.props.idsite_resource, this.props.idsite, this.props.matches, "myMaterial")}
      tallyData={this.state.tallyData}
    />
    
  }

  render = () => {
    let {loading, postingExternally, materials, group, siteData, modalInviteIsOpen, archiveMode, matchList, bidGraveyardList, sameList, toggleDetailsToHidden, internalsBid, internalsPost, externalsBid, externalsPost, tallyData, panelDisplayState} = this.state;
    let {title, switchSidePanel, groupsListMode, idsite_resource, idsite, matches, isMobile, isBrowser} = this.props;

    if (loading) return <div className="my-sites-scroll">Getting matches...</div>
    else return (<Fragment>

      {isMobile ? <>

        <div className="bg-gray border-bottom px-2">
          <p className="lead strong pt-1 pb-2 mb-0"><a className="text-light strong"  href="#" onClick={() => switchSidePanel("sidebar")}><i className="fa fa-caret-left mr-2" aria-hidden="true"></i>Sites</a></p>
        </div>

        <MobileDisplaySwitcher
        changeDisplayState={(param) => this.setState({panelDisplayState: param})}
        buttons={[
          {
            icon: "ti ti-clipboard",
            displayState: "header",
            label: "Material",
            isActive: (panelDisplayState == "header"),
          },
          {
            icon: "ti ti-signal",
            displayState: "matches",
            label: "Matches",
            isActive: (panelDisplayState == "matches"),
          },
          {
            icon: "ti ti-truck",
            displayState: "routes",
            label: "Routes",
            isActive: (panelDisplayState == "routes"),
          }

        ]}

      /> 

    </> : null}

    <ReactTooltip />

    {isMobile ? this.renderHeaderView(
      this.renderHeader()
    ) : null}

    {this.renderMatchesView( <>

        <div className="row mb-2">
          <div className="col-12 mx-auto">  

            {isBrowser ? this.renderHeaderView(
              this.renderHeader()
            ) : <div class="mb-0 bg-light" style={{position: 'sticky',top: '0px',zIndex: 200}}>
                <div class="p-2">
                  <p class="fw-600 mb-2">{materials.mine.name}</p>
                  <div class="border-bottom clearfix mb-4">
                    <p class="lead-4 float-left mb-0">{materials.mine.resource} {materials.mine.type == "Surplus/Export" ? "Surplus" : "Short"}</p>

                    <OpenMaterialInfoButton className={"btn-sm float-right mt-2"} />

                  </div>
                </div>
              </div>}

              {this.state.hasExternalAccess ? <>
                <this.HeaderLabel
                  labelText="City-Net Matches"
                  sideButton={/*this.state.materials.mine.status == "external" ? 
                    <ShareDetailsAllButton
                      className="ml-auto ml-0 mt-4 mb-3"
                      callback={() => this.refreshPanel(this.props.idsite_resource, this.props.idsite, this.props.matches, "ShareDetailsAllButton")}
                      idsite_resource={this.props.idsite_resource}
                    />
                    :*/ null
                  }
                  showTally={materials.mine.status == "external" || externalsPost.length > 0}
                  tally={externalsPost.length}
                  tallyColor={undefined}
                  showToggle={false}
                  isTopHeader={true}
                />

                {this.renderStatusBar()}

              <this.RowWrapper>
                {externalsPost.length ? <>
                  {externalsPost.map((material, key) => {
                    return this.renderExternalMatchRow(material, "externalsPost", key);
                  })}

                </>
                :
                  (externalsPost.length == 0 && materials.mine.status == "external" ? <em>No matches were found. We’ll send you an email when you have new matches</em> : null)
                }
              </this.RowWrapper>
            </> : null }


            <this.HeaderLabel
              labelText="Project Matches"
              isTopHeader={false}
              showToggle={false}
              tally={internalsPost.length}
              tallyColor={undefined}
              showTally={true}
            />

            <this.RowWrapper>
              {internalsPost.length ? <Fragment>
                {internalsPost.map((material, key) => {
                  return this.renderInternalMatchRow(material, "internalsPost", key)
                })}
                </Fragment> : 
                  <this.EmptyStateWrapper><em>No matches were found.</em></this.EmptyStateWrapper>
              }
            </this.RowWrapper>

            {externalsBid.length && this.state.hasExternalAccess ? <>
            <this.HeaderLabel
              labelText="External Bid Matches"
              isTopHeader={false}
              showToggle={false}
              tally={externalsBid.length}
              tallyColor={"badge-secondary"}
              showTally={true}
            />
           
            <div className="card border">
              <div className="card-body px-4 pt-1 pb-0">
                <Fragment>
                  {externalsBid.length ? <Fragment>

                    {externalsBid.map((material, key) => {
                      return this.renderExternalMatchRow(material, "externalsBid", key);
                    })}
                    </Fragment> : 
                      <this.EmptyStateWrapper><em>No matches were found. We’ll send you an email when you have new matches</em></this.EmptyStateWrapper>
                    }

                </Fragment> 

              </div>
            </div>  </>: null}

            {internalsBid.length ? <>
            <this.HeaderLabel
              labelText="Bid Matches"
              isTopHeader={false}
              showToggle={false}
              tally={internalsBid.length}
              tallyColor={"badge-secondary"}
              showTally={true}
            />

            <this.RowWrapper>
              {internalsBid.length ? <Fragment>

                {internalsBid.map((material, key) => {
                  return this.renderInternalMatchRow(material, "internalsBid", key);
                })}
                </Fragment> : 
                  <this.EmptyStateWrapper><em>No matches were found. Start an Office Group to cross-check for free!</em></this.EmptyStateWrapper>
              }
            </this.RowWrapper> </>: null}

            {bidGraveyardList.length > 0 ? <Fragment>
              <this.HeaderLabel
                labelText="Bid Graveyard"
                isTopHeader={false}
                showToggle={false}
                tally={bidGraveyardList.length}
                tallyColor={undefined}
                showTally={false}
              />

              <this.RowWrapper>
                {bidGraveyardList.map((material, key) => {
                  return this.renderInternalMatchRow(material, "bidGraveyardList", key);
                })}
              </this.RowWrapper>
            </Fragment> : null}

            {/*sameList.length ? <>
              <this.HeaderLabel
                labelText="Similar Group Imbalances"
                isTopHeader={false}
                showToggle={false}
                tally={sameList.length}
                tallyColor={undefined}
                showTally={false}
              />

              <this.RowWrapper>

                {sameList.length ? <Fragment>

                  {sameList.map((material, key) => {
                    return this.renderInternalMatchRow(material, "sameList", key);
                  })}

                </Fragment> :

                 !group || !group.idpmgroup ? 

                    <this.EmptyStateWrapper><em>You do not belong to an office group</em></this.EmptyStateWrapper>

                  : <this.EmptyStateWrapper><em>No in-house competition found.</em></this.EmptyStateWrapper>}

              </this.RowWrapper></> : null*/}

          </div>
        </div>
    </>)}

    {this.renderSandboxView(
      <SandboxMap 
        locked={true} 
        sitesList={[]}
        groupSitesList={[]}
        getSiteList={() => {return []}} 
        sharedSite={null}
        setMapLocked={() => {return null}}
        sharedRoutes={this.state.routes}
        originSite={this.state.siteData}
        idsite_resource={idsite_resource}
        toggleRouteVisible={this.toggleRouteVisible}
        displaySites={false}
        isBrowser={this.props.isBrowser}
        isMobile={this.props.isMobile}
        routesMapBounds={this.state.routesMapBounds}
      />
    )}

    {/*<ModalShareDetails idsite_resource={materials.mine.idsite_resource} parent="matches" callback={this.updateMyDetails} forceReload={this.state.reloadSharedDetails} />*/}

    <OptInModal 
      callback={async (goToBlastReferral) => {
        this.setState({postingExternally: true}, () => console.log("BUG-1046", "Opt in modal rerender"))
        if (goToBlastReferral == true){
          await HaulCheckService.toggleStatus(idsite_resource);
          await HaulCheckService.getMatches(idsite);
          window.location.href = url.frontend + "/haul-check-invite/post";
        } else {
          await HaulCheckService.toggleStatus(idsite_resource);
          await HaulCheckService.getMatches(idsite);
          this.refreshPanel(idsite_resource, idsite, matches, "setStatus");
        }
      }} 
      idsite_resource={idsite_resource}
      iddiscoverymode={materials.mine.iddiscoverymode}
    />

    </Fragment>)
  }

  renderExternalMatchRow = (material, list, key) => {
    let {materials} = this.state;
    let {title, switchSidePanel, groupsListMode, idsite_resource, idsite, matches, isMobile, isBrowser} = this.props;
    return <ExternalMatchRow
      match={material}
      key={material.idmatchsiteresource}
      list={list}
      switchSidePanel={switchSidePanel}
      groupsListMode={groupsListMode}
      parentMaterial={materials.mine}
      refreshPanel={/*() => this.refreshPanel(this.props.idsite_resource, this.props.idsite, this.props.matches, "externalMatchRow")*/ this.getExternalMatches(this.props.idsite_resource, this.props.idsite)}
      isMobile={isMobile}
      isBrowser={isBrowser}
      toggleRouteVisible={this.toggleRouteVisible}
      getRouteExistsAndIsVisible={this.getRouteExistsAndIsVisible}
      history={this.props.history}
      openMessages={this.props.openMessages}
    />
  }

  renderInternalMatchRow = (material, list, key) => {
    let {title, switchSidePanel, groupsListMode, idsite_resource, idsite, matches, isMobile, isBrowser} = this.props;
    return <MaterialRow
      passedMaterial = {material}
      key={material.idsite_resource}
      list={list}
      switchSidePanel={switchSidePanel}
      groupsListMode={groupsListMode}
      refreshPanel={() => this.refreshPanel(this.props.idsite_resource, this.props.idsite, this.props.matches, "materialRow")}
      isMobile={isMobile}
      isBrowser={isBrowser}
      toggleRouteVisible={this.toggleRouteVisible}
      getRouteExistsAndIsVisible={this.getRouteExistsAndIsVisible}
      hasExternalAccess={this.state.hasExternalAccess}
    />
  }

  openOptInModal = () => {
    let {idsite_resource} = this.props;
    let button = document.getElementById("open-premium-"+idsite_resource);
    if (button){
      button.click();
    }
  }

}
