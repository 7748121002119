import { url, earthidealAPIURL } from '../constants'
import JwtDecode from 'jwt-decode'

export const PMGroupsService = {
  getCompanyImbalances,
  getResourceCompanyImbalances,
  addPmGroup,
  addPmGroupMembers,
  removePmGroupMembers,
  getPmGroupByEmail,
  getPmGroupMembersByEmail,
  getMasterTableInfo
}

async function getCompanyImbalances(email) {
  const resp = await fetch(`${url.backend}/pmgroups/getcompanyimbalances/${email}`).then(handleResponse)
  return resp.resourcesSitesList
}


async function getResourceCompanyImbalances(email, idsiteresource, tallyViews) {
  let data = {email, idsiteresource, tallyViews}

   const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }

  const resp = await fetch(`${url.backend}/pmgroups/getResourceCompanyImbalances`, requestOptions).then(handleResponse)
  return resp
}


async function addPmGroup(data) {

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }

  const resp = await fetch(`${url.backend}/pmgroups/add`, requestOptions)
    .then(handleResponse)

  return resp
}

async function addPmGroupMembers(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }

  const resp = await fetch(`${url.backend}/pmgroups/addToPmGroup`, requestOptions)
    .then(handleResponse)

  return resp
}

async function removePmGroupMembers(data) {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null

  data.username = username;

  console.log("removePmGroupMembers username:", username);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }

  const resp = await fetch(`${url.backend}/pmgroups/removeFromPmGroup`, requestOptions)
    .then(handleResponse)

  return resp
}

async function getPmGroupByEmail(email, origin) {
  //console.log("Calling getPmGroupByEmail from " + origin);
  if (email){
    const resp = await fetch(`${url.backend}/pmgroups/getpmgroupbyemail/${email}`).then(handleResponse)
    return resp.pmGroup
  } else return null;
}

async function getPmGroupMembersByEmail(email, origin) {
  //console.log("Calling getPmGroupMembersByEmail from " + origin);
  const resp = await fetch(`${url.backend}/pmgroups/getpmgroupmembers/${email}`).then(handleResponse)
  return resp.members
}


async function getMasterTableInfo() {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const email = userDecoded ? userDecoded['email'] : null;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({username, email})
  }

  const resp = await fetch(`${url.backend}/pmgroups/getMasterTableInfo`, requestOptions)
    .then(handleResponse)

  return resp.masterTableInfo;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
